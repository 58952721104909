import React, { useEffect, useRef } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { fetchAccommodationDetails } from "../../data/accommodation/accommodationDetailsSlice";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const AccommodationDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const { roomUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { accommodationDetails, loading, error } = useSelector(
    (state) => state.accommodationDetails
  );
  useEffect(() => {
    dispatch(fetchAccommodationDetails({ hotelUrl, roomUrl, language }));
  }, [dispatch, roomUrl, hotelUrl, language]);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const setSwiperRef2 = (swiper) => {
    swiperRef2.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{accommodationDetails.metatagTitle}</title>
            <meta
              name="description"
              content={accommodationDetails.metatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        {" "}
                        <img
                          src={
                            isMobile
                              ? accommodationDetails.roomBannerMobile
                              : isTablet
                              ? accommodationDetails.roomBannerTablet
                              : accommodationDetails.roomBanner
                          }
                          alt="room banner"
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div
                        data-split="lines"
                        data-anim-child="split-lines delay-3"
                      >
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {accommodationDetails.roomName}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/accommodation`}
                >
                  {language === "ru"
                    ? "Номера"
                    : language === "en"
                    ? "Accommodation"
                    : "Unterkunft"}
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {accommodationDetails.roomName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-md">
            <div className="container">
              <div className="row y-gap-40 x-gap-60 justify-between">
                <div className="col-xl-7 col-lg-7">
                  {/* <div className="fw-500 mb-30">$173 PER NIGHT </div> */}
                  <h2 className="text-40"> {accommodationDetails.roomName}</h2>
                  <div className="d-flex flex-wrap x-gap-50 md:x-gap-20 pt-40">
                    <div className="d-flex items-center text-accent-1">
                      <i className="icon-size text-20 mr-10"></i>{" "}
                      {language === "ru"
                        ? "Размер:"
                        : language === "en"
                        ? "Size:"
                        : "Größe:"}{" "}
                      {accommodationDetails.roomSize}
                    </div>

                    <div className="d-flex items-center text-accent-1">
                      <i className="icon-guest text-20 mr-10"></i>
                      {language === "ru"
                        ? "Максимальное кол-во гостей:"
                        : language === "en"
                        ? "Max Occupancy:"
                        : "Maximale Belegung: "}{" "}
                      {accommodationDetails.maxOccupancy}
                    </div>

                    <div className="d-flex items-center text-accent-1">
                      <FontAwesomeIcon
                        icon={faExpand}
                        className="text-20 mr-10"
                        style={{ opacity: "0.7", color: "#122223" }}
                      />{" "}
                      {language === "ru"
                        ? "Вид:"
                        : language === "en"
                        ? "View:"
                        : "Aussicht:"}{" "}
                      {accommodationDetails.roomView}
                    </div>
                    <div className="d-flex items-center text-accent-1">
                      <i className="icon-bed-2 text-20 mr-10"></i>{" "}
                      {accommodationDetails.roomBed}
                    </div>
                  </div>

                  <div className="line -horizontal bg-border mt-50 mb-50"></div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${accommodationDetails.roomDetails}`,
                    }}
                  ></p>
                </div>

                <div className="col-xl-5 col-lg-5">
                  <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                    <h2 className="text-40">
                      {language === "ru"
                        ? "В номерах"
                        : language === "en"
                        ? "Amenities"
                        : "Ausstattung"}{" "}
                    </h2>
                    <div className="row x-gap-50 y-gap-20 justify-between pt-40 ">
                      <div className="row  y-gap-30 justify-between align-items-center">
                        {accommodationDetails.roomAmenities?.map(
                          (item, index) => (
                            <>
                              <div className="col-6" key={index}>
                                <div className="d-flex items-center ">
                                  <img
                                    alt="icon"
                                    className="mr-30 text-right"
                                    width="30"
                                    src={item.roomAmenitiesPhoto}
                                  />
                                  <div>{item.roomAmenitiesName} </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line -horizontal bg-border mt-50 mb-50"></div>
            </div>
          </section>
          {accommodationDetails.roomsGalleries?.length > 0 && (
            <section className="layout-pt-lg layout-pb-lg bg-light-1">
              <div className="container">
                <div className="row justify-center text-center mb-100 sm:mb-50">
                  <div className="col-auto">
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {language === "ru"
                        ? "Изучить"
                        : language === "en"
                        ? "EXPLORE"
                        : "ERKUNDEN"}
                    </div>
                    <h2 className="text-64 md:text-40">
                      {language === "ru"
                        ? "Галерея"
                        : language === "en"
                        ? "Gallery"
                        : "Galerie"}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="container">
                  <div className="row justify-center">
                    <div className="col-lg-10 col-md-11">
                      <Swiper
                        className="overflow-visible"
                        modules={[Navigation]}
                        spaceBetween={180}
                        slidesPerView={1}
                        onSwiper={setSwiperRef}
                      >
                        {accommodationDetails.roomsGalleries?.map(
                          (image, index) => (
                            <SwiperSlide key={index}>
                              <div className="ratio ratio-16:9">
                                <img
                                  src={image.photoFile}
                                  alt=""
                                  className="img-ratio"
                                />
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>

                <div className="navAbsolute -type-3">
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-prev"
                    onClick={() => swiperRef.current?.slidePrev()} // Call handlePrev when prev button is clicked
                  >
                    <i className="icon-arrow-left text-24 text-white"></i>
                  </button>
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-next"
                    onClick={() => swiperRef.current?.slideNext()} // Call handleNext when next button is clicked
                  >
                    <i className="icon-arrow-right text-24 text-white"></i>
                  </button>
                </div>
              </div>
            </section>
          )}
          <section className=" pt-50 layout-pb-lg">
            <div className="container">
              <div className="row y-gap-30 justify-between items-end">
                <div className="col-auto">
                  <div className="text-15 uppercase mb-30 sm:mb-10">
                    {language === "ru"
                      ? "Изучить"
                      : language === "en"
                      ? "EXPLORE"
                      : "ERKUNDEN"}
                  </div>
                  <h2 className="text-64 md:text-40 lh-065">
                    {language === "ru"
                      ? "Похожие комнаты"
                      : language === "en"
                      ? "Similar Rooms"
                      : "Ähnliche Zimmer"}
                  </h2>
                </div>

                <div className="col-auto">
                  <button
                    className="button -type-1"
                    onClick={() =>
                      navigate(
                        `/${accommodationDetails.hotelUrl}/accommodation`
                      )
                    }
                  >
                    <i className="-icon icon-arrow-circle-right text-30"></i>
                    {language === "ru"
                      ? "Посмотреть все комнаты"
                      : language === "en"
                      ? "ALL ROOMS"
                      : "ALLE ZIMMER"}
                  </button>
                </div>
              </div>

              <div className="relative mt-100 sm:mt-50">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints={{
                    300: { slidesPerView: 1 },
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                  }}
                  className="overflow-hidden"
                  modules={[Navigation]}
                  onSwiper={setSwiperRef2}
                >
                  {/* Example of adding multiple slides */}
                  {accommodationDetails.otherRooms?.map((room) => (
                    <SwiperSlide key={room.roomUrl}>
                      <Link
                        to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                        className="roomCard -type-2 -hover-button-center"
                      >
                        <div className="roomCard__image -no-rounded ratio ratio-45:54 -hover-button-center__wrap">
                          <img
                            src={room.roomPhoto}
                            alt={room.roomName}
                            className="img-ratio"
                          />

                          <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                            <span className="text-15 fw-500 text-white text-center">
                              {language === "ru"
                                ? "Подробнее"
                                : language === "en"
                                ? "More Details"
                                : "Mehr Details"}
                            </span>
                          </div>
                        </div>
                        <div className="roomCard__content mt-30">
                          <div className="d-flex justify-between items-end">
                            <h3 className="roomCard__title lh-065 text-40 md:text-24">
                              {room.roomName}
                            </h3>
                          </div>
                          <div className="d-flex flex-wrap x-gap-30 pt-30">
                            <div className="d-flex items-center text-accent-1">
                              <i className="icon-size text-20 mr-10"></i>{" "}
                              {language === "ru"
                                ? "Размер:"
                                : language === "en"
                                ? "Size:"
                                : "Größe:"}{" "}
                              {room.roomSize}
                            </div>
                            <div className="d-flex items-center text-accent-1">
                              <i className="icon-guest text-20 mr-10"></i>
                              {language === "ru"
                                ? "Максимальное кол-во гостей:"
                                : language === "en"
                                ? "Max Occupancy:"
                                : "Maximale Belegung: "}
                              {room.maxOccupancy}
                            </div>
                            <div className="d-flex items-center text-accent-1">
                              <FontAwesomeIcon
                                icon={faExpand}
                                className="text-20 mr-10"
                                style={{ opacity: "0.7", color: "#122223" }}
                              />{" "}
                              {language === "ru"
                                ? "Вид:"
                                : language === "en"
                                ? "View:"
                                : "Aussicht:"}
                              {room.roomView}
                            </div>
                            <div className="d-flex items-center text-accent-1">
                              <i className="icon-bed-2 text-20 mr-10"></i>
                              {room.roomBed}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div
                  className="navAbsolute -type-2"
                  style={{ left: "-40px", right: "-40px" }}
                >
                  <button
                    className="button -outline-accent-1 size-80 md:size-60 flex-center rounded-full"
                    onClick={() => swiperRef2.current?.slidePrev()}
                  >
                    <i className="icon-arrow-left text-24"></i>
                  </button>
                  <button
                    className="button -outline-accent-1 size-80 md:size-60 flex-center rounded-full"
                    onClick={() => swiperRef2.current?.slideNext()}
                  >
                    <i className="icon-arrow-right text-24"></i>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default AccommodationDetails;
