import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import ScrollToTop from "./ScrollToTop.jsx";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";

import "./App.css";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import LayoutGroup from "./components/LayoutGroup.jsx";
import HomeGroup from "./pages/HomeGroup.jsx";
import ContactUsGroup from "./pages/contact-us/ContactUsGroup.jsx";
import ContactUsHotels from "./pages/contact-us/ContactUsHotels.jsx";
import HomeHotel from "./pages/HomeHotel.jsx";
import LayoutHotels from "./components/LayoutHotels.jsx";
import Accommodation from "./pages/accommodation/Accommodation.jsx";
import AccommodationDetails from "./pages/accommodation/AccommodationDetails.jsx";
import Dining from "./pages/dining/Dining.jsx";
import DiningDetails from "./pages/dining/DiningDetails.jsx";

import FacilitiesDetails from "./pages/facilities/FacilitiesDetails.jsx";
import MeetingAndEventsDetails from "./pages/meeting-events/MeetingAndEventsDetails.jsx";
import MeetingAndEvents from "./pages/meeting-events/MeetingAndEvents.jsx";
import Spa from "./pages/spa/Spa";
import MeetingAndEventsGroup from "./pages/meeting-events-group/MeetingAndEventsGroup.jsx";
import MeetingAndEventsDetailsGroup from "./pages/meeting-events-group/MeetingAndEventsDetailsGroup.jsx";
import Gallery from "./pages/gallery/Gallery";
import Facilities from "./pages/facilities/Facilities.jsx";
import OnlineCheckIn from "./pages/online-check-in/OnlineCheckIn.jsx";

import Faq from "./pages/faq/Faq.jsx";
import { fetchHotels } from "./data/hotelsSlice";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import Restaurants from "./pages/restaurants/Restaurants.jsx";
import Bars from "./pages/restaurants/Bars.jsx";
import OffersGroup from "./pages/Offers/OffersGroup.jsx";
import OffersHotel from "./pages/Offers/OffersHotel.jsx";
import OfferDetails from "./pages/Offers/OfferDetails.jsx";
import DiningType from "./pages/dining/DiningType.jsx";
import AllInclusive from "./pages/dining/AllInclusive";
import FaqHotels from "./pages/faq/FaqHotels.jsx";
import SpaDetails from "./pages/spa/SpaDetails.jsx";
import { setLanguage } from "./data/languageSlice.js";
import { fetchLayoutGroup } from "./data/layoutGroupSlice.js";
import { fetchHomeGroup } from "./data/homeGroup/homeGroupSlice.js";

function App() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { layoutGroup } = useSelector((state) => state.layoutGroup);

  useEffect(() => {
    dispatch(fetchLayoutGroup(language));
  }, [dispatch, language]);

  const supportedLanguages = layoutGroup.groupHeader?.languages.map(
    (lang) => lang.languageAbbreviation
  ) || ["en"];

  useEffect(() => {
    dispatch(fetchHotels(language));
  }, [dispatch, language]);
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean);
    const savedLanguage = localStorage.getItem("language") || "en";

    if (pathSegments.length === 0) {
      window.location.replace(
        `${currentUrl.origin}/${savedLanguage}${currentUrl.pathname}${currentUrl.search}`
      );
    } else if (
      pathSegments[0] == supportedLanguages[0] ||
      pathSegments[0] == "de" ||
      pathSegments[0] == supportedLanguages[1] ||
      pathSegments[0] == "en" ||
      pathSegments[0] == supportedLanguages[2] ||
      pathSegments[0] == "ru" ||
      supportedLanguages.includes(pathSegments[0])
    ) {
      const selectedLanguage = pathSegments[0];
      if (selectedLanguage !== savedLanguage) {
        dispatch(setLanguage(selectedLanguage));
        localStorage.setItem("language", selectedLanguage);
      }
    } else {
      window.location.replace(
        `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`
      );
    }

    if (currentUrl.pathname.includes("aladdin-beach-resort")) {
      localStorage.setItem("hotelUrl", "aladdin-beach-resort");
    } else if (currentUrl.pathname.includes("ali-baba-palace")) {
      localStorage.setItem("hotelUrl", "ali-baba-palace");
    } else if (currentUrl.pathname.includes("jasmine-village")) {
      localStorage.setItem("hotelUrl", "jasmine-village");
    } else {
      localStorage.removeItem("hotelUrl");
    }
  }, [dispatch, supportedLanguages]);

  // const [cliced, setCliced] = useState(true);
  // const toggleLanguage = () => {
  //   let currentUrl = window.location.href;

  //   if (currentUrl.includes("/en")) {
  //     currentUrl = currentUrl.replace("/en", "/ar");
  //     setCliced(false);
  //   } else if (currentUrl.includes("/ar")) {
  //     currentUrl = currentUrl.replace("/ar", "/en");
  //     setCliced(true);
  //   }

  //   window.history.pushState({ path: currentUrl }, "", currentUrl);

  //   document.body.style.direction =
  //     document.body.style.direction === "rtl" ? "ltr" : "rtl";
  // };

  return (
    <>
      <Router basename={`/${language}`}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LayoutGroup />}>
            <Route index element={<HomeGroup />} />
            <Route
              path="meetingandevents"
              element={<MeetingAndEventsGroup />}
            />
            <Route
              path="meetingandevents/meetingandevents-details"
              element={<MeetingAndEventsDetailsGroup />}
            />
            <Route path="online-check-in" element={<OnlineCheckIn />} />
            <Route path="faq" element={<Faq />} />
            <Route path="offers" element={<OffersGroup />} />
            <Route path="contact-us" element={<ContactUsGroup />} />{" "}
            <Route path="terms-conditions" element={<TermsAndConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="/:hotelUrl" element={<LayoutHotels />}>
            <Route index element={<HomeHotel />} />
            <Route path="accommodation" element={<Accommodation />} />
            <Route path="spa" element={<Spa />} />
            <Route path="spa/:facilityUrl" element={<SpaDetails />} />
            <Route
              path="accommodation/:roomUrl"
              element={<AccommodationDetails />}
            />

            <Route path="dining" element={<Dining />} />
            <Route path="all-inclusive" element={<AllInclusive />} />
            <Route path=":filterBy" element={<DiningType />} />
            <Route path="dining/:restaurantUrl" element={<DiningDetails />} />
            <Route path="facilities" element={<Facilities />} />
            <Route
              path="facilities/:facilityUrl"
              element={<FacilitiesDetails />}
            />

            <Route path="meetingandevents" element={<MeetingAndEvents />} />
            <Route
              path="meetingandevents/:facilityUrl"
              element={<MeetingAndEventsDetails />}
            />
            <Route path="faq" element={<FaqHotels />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="offers" element={<OffersHotel />} />
            <Route path="offers/offer-details" element={<OfferDetails />} />
            <Route path="contact-us" element={<ContactUsHotels />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
