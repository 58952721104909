import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqGroup } from "../../data/faqGroup/faqGroupSlice";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
SwiperCore.use([Pagination, Navigation]);
const OffersGroup = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const [openPanels, setOpenPanels] = useState([]);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const togglePanel = (index) => {
    if (openPanels.includes(index)) {
      // If panel is open, remove it from the array
      setOpenPanels(openPanels.filter((i) => i !== index));
    } else {
      // Otherwise add it to the array
      setOpenPanels([...openPanels, index]);
    }
  };
  return (
    <>
      <section className="hero -type-10 banner-mobile">
        <div
          className="hero__slider js-section-slider"
          data-gap="0"
          data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
          data-nav-prev="js-sliderHero-prev"
          data-nav-next="js-sliderHero-next"
          data-pagination="js-sliderHero-pagination"
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="hero__slide">
                <div className="hero__bg">
                  <div className="img-ratio">
                    {" "}
                    <img
                      src="https://cms.hwaidakhotels.com/images/uploads/banner-check-in.jpg"
                      alt=""
                      className="img-ratio"
                    />
                  </div>
                </div>

                <div className="hero__content text-center">
                  <div>
                    <Fade direction="up" delay="100">
                      <h1 className="hero__title capitalize text-white">
                        Special Offers
                      </h1>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div role="presentation" className="breadcrumbs-wrapper py-3 bg-light-1">
        <div className="container-fluid">
          <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
            <Link
              className="d-flex align-items-center"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to="/"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: "18px" }} fontSize="inherit" />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              to={`/${hotelUrl}`}
            >
              {hotelUrl === "aladdin-beach-resort"
                ? "Aladdin Beach Resort "
                : hotelUrl === "ali-baba-palace-hotel" ||
                  hotelUrl === "ali-baba-palace"
                ? "Ali Baba Palace"
                : "Jasmine Village"}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Special Offers
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <section className="layout-pt-lg  ">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-xl-10 col-lg-10">
              <div className="text-15 uppercase mb-20">
                {" "}
                {hotelUrl === "aladdin-beach-resort"
                  ? "Aladdin Beach Resort "
                  : hotelUrl === "ali-baba-palace-hotel" ||
                    hotelUrl === "ali-baba-palace"
                  ? "Ali Baba Palace"
                  : "Jasmine Village"}{" "}
              </div>
              <h2 className="text-50 lg:text-40 md:text-30">Special Offers</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-lg-5  layout-pb-lg">
        <div className="container">
          <div className="row x-gap-60 y-gap-60 pt-100 sm:pt-50">
            <div className="col-md-6">
              <Link
                to={`/${hotelUrl}/offers/offer-details`}
                className="roomCard -type-1 -hover-button-center -image-border"
              >
                <div>
                  <div className="roomCard__image ratio ratio-76:62 -hover-button-center__wrap">
                    <img
                      src="img/cards/rooms/3/1.png"
                      alt=""
                      className="img-ratio"
                    />

                    <div className="-image-border__item"></div>

                    <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                      <span className="text-15 fw-500 text-white">
                        BOOK NOW{" "}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="roomCard__content mt-30">
                  <div className="d-flex justify-between items-end">
                    <h3 className="roomCard__title lh-065 text-40">
                      Small Room{" "}
                    </h3>
                    <div className="roomCard__price text-accent-1">
                      <mark>$300 / NIGHT</mark>
                    </div>
                  </div>

                  <div className="d-flex x-gap-20 y-gap-10 pt-30 text-accent-1">
                    <div className="d-flex items-center">
                      <i className="icon-size d-flex text-20 mr-10"></i>
                      <div>30M2 </div>
                    </div>

                    <div className="d-flex items-center">
                      <i className="icon-guest d-flex text-20 mr-10"></i>
                      <div>2 GUEST </div>
                    </div>

                    <div className="d-flex items-center">
                      <i className="icon-bed-2 d-flex text-20 mr-10"></i>
                      <div>1 BED </div>
                    </div>

                    <div className="d-flex items-center">
                      <i className="icon-bath d-flex text-20 mr-10"></i>
                      <div>1 BATH </div>
                    </div>
                  </div>

                  <p className="mt-20">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Consectetur, unde officiis impedit tenetur tempora fugiat at
                    fugit assumenda eius quod aperiam dolor dolores possimus
                    consequuntur repudiandae distinctio laboriosam esse
                    perspiciatis?
                  </p>

                  <button className="button -type-1 mt-30">
                    <i className="-icon d-flex icon-arrow-circle-right text-30"></i>
                    Book Now
                  </button>
                </div>
              </Link>
            </div>

            <div className="col-md-6">
              <Link
                to={`/${hotelUrl}/offers/offer-details`}
                className="roomCard -type-1 -hover-button-center -image-border"
              >
                <div>
                  <div className="roomCard__image ratio ratio-76:62 -hover-button-center__wrap">
                    <img
                      src="img/cards/rooms/3/2.png"
                      alt=""
                      className="img-ratio"
                    />

                    <div className="-image-border__item"></div>

                    <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                      <span className="text-15 fw-500 text-white">
                        BOOK NOW{" "}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="roomCard__content mt-30">
                  <div className="d-flex justify-between items-end">
                    <h3 className="roomCard__title lh-065 text-40">
                      Family Room{" "}
                    </h3>
                    <div className="roomCard__price text-accent-1">
                      <del>$499 / NIGHT</del> <mark>$300 / NIGHT</mark>
                    </div>
                  </div>

                  <div className="d-flex x-gap-20 y-gap-10 pt-30 text-accent-1">
                    <div className="d-flex items-center">
                      <i className="icon-size d-flex text-20 mr-10"></i>
                      <div>30M2 </div>
                    </div>

                    <div className="d-flex items-center">
                      <i className="icon-guest d-flex text-20 mr-10"></i>
                      <div>2 GUEST </div>
                    </div>

                    <div className="d-flex items-center">
                      <i className="icon-bed-2 d-flex text-20 mr-10"></i>
                      <div>1 BED </div>
                    </div>

                    <div className="d-flex items-center">
                      <i className="icon-bath d-flex text-20 mr-10"></i>
                      <div>1 BATH </div>
                    </div>
                  </div>

                  <p className="mt-20">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Consectetur, unde officiis impedit tenetur tempora fugiat at
                    fugit assumenda eius quod aperiam dolor dolores possimus
                    consequuntur repudiandae distinctio laboriosam esse
                    perspiciatis?
                  </p>

                  <button className="button -type-1 mt-30">
                    <i className="-icon d-flex icon-arrow-circle-right text-30"></i>
                    Book Now
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OffersGroup;
