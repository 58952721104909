import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl";

const initialState = {
  diningType: {},
  loading: true,
  error: null,
};

export const fetchDiningType = createAsyncThunk(
  "dining/fetchDiningType",
  async ({ hotelUrl, filterBy, language }) => {
    const response = await axios.get(
      `${baseUrl}/api/Restaurant/${language}/${hotelUrl}/${filterBy}`
    );
    return response.data;
  }
);

const diningTypeSlice = createSlice({
  name: "diningType",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDiningType.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDiningType.fulfilled, (state, action) => {
        state.loading = false;
        state.diningType = action.payload;
      })
      .addCase(fetchDiningType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default diningTypeSlice.reducer;
