import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ModalVideo from "react-modal-video";

import "react-modal-video/css/modal-video.css";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faCancel,
  faClock,
  faMinus,
  faPlus,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { fetchFaqGroup } from "../../data/faqGroup/faqGroupSlice";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { fetchSpaDetails } from "../../data/spa/spaDetailsSlice";
import { Helmet } from "react-helmet";
import Loader from "../../Loader";
SwiperCore.use([Pagination, Navigation]);
const SpaDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const { homeGroup } = useSelector((state) => state.homeGroup);
  const [openPanels, setOpenPanels] = useState([]);
  const language = useSelector((state) => state.language.language);
  const { facilityUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { spaDetails, loading, error } = useSelector(
    (state) => state.spaDetails
  );

  useEffect(() => {
    dispatch(fetchSpaDetails({ hotelUrl, facilityUrl, language }));
  }, [dispatch, facilityUrl, hotelUrl, language]);

  const togglePanel = (index) => {
    if (openPanels.includes(index)) {
      // If panel is open, remove it from the array
      setOpenPanels(openPanels.filter((i) => i !== index));
    } else {
      // Otherwise add it to the array
      setOpenPanels([...openPanels, index]);
    }
  };
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const rooms = [
    {
      id: 1,
      image: "r1.jpg",
      title: "Superior Room",
      price: "$499 / NIGHT",
      features: ["30M2", "Sea View", "3 Persons"],
    },
    {
      id: 2,
      image: "r2.jpg",
      title: "Pool View Room",
      price: "$499 / NIGHT",
      features: ["30M2", "Pool View", "3 Persons"],
    },
    {
      id: 3,
      image: "r3.jpg",
      title: "Family Suite",
      price: "$499 / NIGHT",
      features: ["30M2", "Pool View", "3 Persons"],
    },
    {
      id: 4,
      image: "r4.jpg",
      title: "Deluxe Room",
      price: "$499 / NIGHT",
      features: ["30M2", "Pool View", "3 Persons"],
    },
  ];

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{spaDetails?.metatagTitle}</title>
            <meta name="description" content={spaDetails?.metatagDescription} />
          </Helmet>

          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        {" "}
                        <img
                          src={
                            isMobile
                              ? spaDetails?.facilityBannerMobile
                              : isTablet
                              ? spaDetails.facilityBannerTablet
                              : spaDetails.facilityBanner
                          }
                          alt="spa banner"
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div
                        data-split="lines"
                        data-anim-child="split-lines delay-3"
                      >
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            <i className="d-flex justify-center text-50 icon-rocks mb-30"></i>{" "}
                            {spaDetails.facilityName}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/spa`}
                >
                  Spa & Wellness
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {spaDetails.facilityName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-md layout-pb-lg">
            <div className="container">
              <div className="row y-gap-40 x-gap-60 justify-between">
                <div className="col-12 col-xl-8 col-lg-8">
                  <Fade
                    cascade
                    direction="up"
                    duration="500"
                    delay="0"
                    triggerOnce="true"
                  >
                    {" "}
                    <div className="col-xl-8 col-lg-10">
                      <h2 className="text-45 lg:text-45 md:text-40">
                        {" "}
                        {spaDetails.facilityName}
                      </h2>

                      <p
                        className="lh-18 mt-20 md:mt-30"
                        dangerouslySetInnerHTML={{
                          __html: `${spaDetails.facilityDetails}`,
                        }}
                      />
                    </div>
                  </Fade>
                  {spaDetails.spaTypes?.length > 0 && (
                    <>
                      {" "}
                      <hr />
                      <div>
                        <h2 className="text-45 lg:text-45 md:text-40 mt-20 mb-20">
                          {" "}
                          SPA Menu
                        </h2>
                        <div className="accordion -type-1 row y-gap-10">
                          {spaDetails.spaTypes?.map((item, index) => (
                            <div key={index} className="col-12">
                              <div className="accordion__item">
                                <div
                                  className="accordion__button d-flex align-baseline justify-between px-30 py-20 bg-light-1"
                                  onClick={() => togglePanel(index)}
                                >
                                  <div className="text-sec text-24 fw-500 lh-1">
                                    {item.spaServiceTypeName}
                                  </div>
                                  <div className="accordion__icon">
                                    {openPanels.includes(index) ? (
                                      <FontAwesomeIcon icon={faMinus} />
                                    ) : (
                                      <FontAwesomeIcon icon={faPlus} />
                                    )}
                                  </div>
                                </div>
                                {openPanels.includes(index) && (
                                  <div className="accordion__content">
                                    <div className="px-30 py-30">
                                      {item.spaServices?.map(
                                        (service, index) => (
                                          <>
                                            <p
                                              key={index}
                                              className="text-20 lg:text-45 md:text-40 mt-20 mb-20"
                                            >
                                              {" "}
                                              <b>{service.spaservicesName}</b>(
                                              {service.spaservicesTime}) &nbsp;
                                              &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                                              <b
                                                style={{
                                                  color: "#fd7e14",
                                                  float: "right",
                                                }}
                                              >
                                                {service.spaservicesPrice
                                                  ? service.spaservicesPrice
                                                  : "0$"}
                                              </b>
                                            </p>
                                            <p>{service.spaservicesDetails}</p>
                                            <hr />
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-12 col-xl-4 col-lg-4">
                  <Fade direction="right">
                    <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                      {spaDetails.openingHours && (
                        <>
                          <p className="text-20">
                            {" "}
                            <FontAwesomeIcon icon={faClock} className="mr-10" />
                            Opening Hours
                          </p>
                          <div className="row x-gap-50 y-gap-20 justify-between pt-20">
                            <div className="col-sm-12">
                              <ul>
                                <li>
                                  <Link to="#." className="text-15">
                                    {spaDetails.openingHours}
                                  </Link>
                                </li>
                              </ul>
                              <hr style={{ color: "#ccc" }} />
                            </div>
                          </div>
                        </>
                      )}
                      {spaDetails.cancellationPolicy && (
                        <>
                          {" "}
                          <p className="text-20">
                            {" "}
                            <FontAwesomeIcon
                              icon={faCancel}
                              className="mr-10"
                            />
                            Cancellation Policy
                          </p>
                          <div className="row x-gap-50 y-gap-20 justify-between pt-20">
                            <div className="col-sm-12">
                              <ul>
                                <li>
                                  <Link to="#." className="text-15">
                                    {spaDetails.cancellationPolicy}
                                  </Link>
                                </li>
                              </ul>
                              <hr style={{ color: "#ccc" }} />
                            </div>
                          </div>
                        </>
                      )}
                      {spaDetails.spaArrival && (
                        <>
                          <p className="text-20">
                            {" "}
                            <FontAwesomeIcon
                              icon={faArrowAltCircleRight}
                              className="mr-10"
                            />
                            SPA Arrival
                          </p>
                          <div className="row x-gap-50 y-gap-20 justify-between pt-20">
                            <div className="col-sm-12">
                              <ul>
                                <li>
                                  <Link to="#." className="text-15">
                                    {spaDetails.spaArrival}
                                  </Link>
                                </li>
                              </ul>
                              <hr style={{ color: "#ccc" }} />
                            </div>
                          </div>
                        </>
                      )}
                      {spaDetails.ageRequirement && (
                        <>
                          {" "}
                          <p className="text-20">
                            {" "}
                            <FontAwesomeIcon
                              icon={faArrowAltCircleRight}
                              className="mr-10"
                            />
                            Age Requirement
                          </p>
                          <div className="row x-gap-50 y-gap-20 justify-between pt-20">
                            <div className="col-sm-12">
                              <ul>
                                <li>
                                  <Link to="#." className="text-15">
                                    {spaDetails.ageRequirement}
                                  </Link>
                                </li>
                              </ul>
                              <hr style={{ color: "#ccc" }} />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="line -horizontal bg-border mt-50 mb-50"></div>
            </div>
          </section>
          {spaDetails.spaGallery?.length > 0 && (
            <section className="layout-pt-lg layout-pb-lg bg-light-1">
              <div className="container">
                <div className="row justify-center text-center mb-100 sm:mb-50">
                  <div className="col-auto">
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      EXPLORE
                    </div>
                    <h2 className="text-64 md:text-40">Gallery</h2>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="container">
                  <div className="row justify-center">
                    <div className="col-lg-10 col-md-11">
                      <Swiper
                        className="overflow-visible"
                        modules={[Navigation]}
                        spaceBetween={180}
                        slidesPerView={1}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        navigation={{
                          prevEl: ".js-slider1-prev",
                          nextEl: ".js-slider1-next",
                        }}
                      >
                        {spaDetails.spaGallery.map((src, index) => (
                          <SwiperSlide key={index}>
                            <div className="ratio ratio-16:9">
                              <img
                                src={src.photoFile}
                                alt=""
                                className="img-ratio"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>

                <div className="navAbsolute -type-3">
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-prev"
                    onClick={handlePrev} // Call handlePrev when prev button is clicked
                  >
                    <i className="icon-arrow-left text-24 text-white"></i>
                  </button>
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-next"
                    onClick={handleNext} // Call handleNext when next button is clicked
                  >
                    <i className="icon-arrow-right text-24 text-white"></i>
                  </button>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};
export default SpaDetails;
