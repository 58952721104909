import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { fetchAccommodation } from "./../../data/accommodation/accommodationSlice";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";

const Accommodation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const { accommodation, loading, error } = useSelector(
    (state) => state.accommodation
  );
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    dispatch(fetchAccommodation({ hotelUrl, language }));
  }, [dispatch, hotelUrl, language]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{accommodation?.pageDetails?.pageTitle}</title>
            <meta
              name="description"
              content={accommodation?.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        {" "}
                        <img
                          src={
                            isMobile
                              ? accommodation.pageDetails?.pageBannerMobile
                              : isTablet
                              ? accommodation.pageDetails?.pageBannerTablet
                              : accommodation.pageDetails?.pageBannerPC
                          }
                          alt="room banner"
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {accommodation.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace "
                    : "Jasmine Village"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {accommodation.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg layout-pb-lg accommodation-section">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-6 col-lg-8 col-md-10">
                  <Fade cascade direction="up" triggerOnce="true">
                    {" "}
                    {/* <div className="text-15 uppercase mb-30 sm:mb-10">
                      {accommodation.pageDetails?.pageTitle}
                    </div> */}
                    <h2 className="text-64 md:text-40 lh-11">
                      {" "}
                      {accommodation.pageDetails?.pageTitle}
                    </h2>
                    <p className="mt-40">
                      {accommodation.pageDetails?.pageText}
                    </p>
                  </Fade>
                </div>
              </div>
            </div>

            <div className="px-60 md:px-15">
              {accommodation.roomsList?.map((room, index) =>
                index % 2 == 0 ? (
                  <>
                    <div
                      key={room.roomUrl}
                      className="roomCard -type-2 pt-100 sm:pt-50"
                    >
                      <div className="roomCardGrid">
                        <div className="">
                          <div className="roomCard__content">
                            <div className="d-flex  justify-between items-end">
                              <h3 className="roomCard__title lh-065 text-64 md:text-40">
                                {room.roomName}
                              </h3>
                            </div>

                            <div className="d-flex x-gap-20 y-gap-20 pt-40 md:pt-30 flex-wrap ">
                              <div className="d-flex items-center text-accent-1   ">
                                <i className="icon-size text-20 mr-10"></i>
                                {language === "ru"
                                  ? "Размер:"
                                  : language === "en"
                                  ? "Size:"
                                  : "Größe:"}{" "}
                                {room.roomSize}
                              </div>

                              <div className="d-flex items-center text-accent-1  ">
                                <i className="icon-guest text-20 mr-10"></i>
                                {language === "ru"
                                  ? "Максимальное кол-во гостей:"
                                  : language === "en"
                                  ? "Max Occupancy:"
                                  : "Maximale Belegung: "}{" "}
                                {room.maxOccupancy}
                              </div>

                              <div className="d-flex items-center text-accent-1  ">
                                <FontAwesomeIcon
                                  icon={faExpand}
                                  className="text-20 mr-10"
                                  style={{ opacity: "0.7", color: "#122223" }}
                                />{" "}
                                {language === "ru"
                                  ? "Вид:"
                                  : language === "en"
                                  ? "View:"
                                  : "Aussicht:"}{" "}
                                {room.roomView}
                              </div>
                              <div className="d-flex items-center text-accent-1   ">
                                <i className="icon-bed-2 text-20 mr-10"></i>
                                {room.roomBed}
                              </div>
                            </div>

                            <p className="mt-40 md:mt-30">{room.roomSummery}</p>

                            <button
                              onClick={() =>
                                navigate(
                                  `/${room.hotelUrl}/accommodation/${room.roomUrl}`
                                )
                              }
                              className="button -md -type-2 -accent-1 bg-accent-2 mt-50 md:mt-30 "
                            >
                              {language === "ru"
                                ? "Подробнее"
                                : language === "en"
                                ? " ROOM DETAIL"
                                : "Zimmerdetails"}
                            </button>
                          </div>
                        </div>

                        <div className="">
                          <div>
                            <div className="roomCard__image -no-rounded ratio ratio-10:9">
                              <Slide className="img-ratio" duration="1500">
                                {" "}
                                <img
                                  src={room.roomPhoto}
                                  alt={room.roomName}
                                  className="img-ratio"
                                />
                              </Slide>
                              {/* <div className="roomCard__price text-15 fw-500 bg-white text-accent-1 rounded-0">
                $499 / NIGHT{" "}
              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      key={room.roomUrl}
                      className="roomCard -type-2 pt-100 sm:pt-50 two"
                    >
                      <div className="roomCardGrid">
                        <div className="roomCard__image -no-rounded ratio ratio-10:9">
                          <Slide className="img-ratio" duration="1500">
                            {" "}
                            <img
                              src={room.roomPhoto}
                              alt={room.roomName}
                              className="img-ratio"
                            />
                          </Slide>
                        </div>
                        <div className="roomCard__content">
                          <div className="d-flex justify-between items-end">
                            <h3 className="roomCard__title lh-065 text-64 md:text-40">
                              {room.roomName}
                            </h3>
                          </div>

                          <div className="d-flex x-gap-20 y-gap-20 pt-40 md:pt-30 flex-wrap ">
                            <div className="d-flex items-center text-accent-1   ">
                              <i className="icon-size text-20 mr-10"></i>
                              {language === "ru"
                                ? "Размер:"
                                : language === "en"
                                ? "Size:"
                                : "Größe:"}{" "}
                              {room.roomSize}
                            </div>

                            <div className="d-flex items-center text-accent-1  ">
                              <i className="icon-guest text-20 mr-10"></i>
                              {language === "ru"
                                ? "Максимальное кол-во гостей:"
                                : language === "en"
                                ? "Max Occupancy:"
                                : "Maximale Belegung: "}{" "}
                              {room.maxOccupancy}
                            </div>

                            <div className="d-flex items-center text-accent-1  ">
                              <FontAwesomeIcon
                                icon={faExpand}
                                className="text-20 mr-10"
                                style={{ opacity: "0.7", color: "#122223" }}
                              />{" "}
                              {language === "ru"
                                ? "Вид:"
                                : language === "en"
                                ? "View:"
                                : "Aussicht:"}{" "}
                              {room.roomView}
                            </div>
                            <div className="d-flex items-center text-accent-1   ">
                              <i className="icon-bed-2 text-20 mr-10"></i>
                              {room.roomBed}
                            </div>
                          </div>

                          <p className="mt-40 md:mt-30">{room.roomSummery}</p>

                          <button
                            onClick={() =>
                              navigate(
                                `/${room.hotelUrl}/accommodation/${room.roomUrl}`
                              )
                            }
                            className="button -md -type-2 -accent-1 bg-accent-2 mt-50 md:mt-30 "
                          >
                            {language === "ru"
                              ? "Подробнее"
                              : language === "en"
                              ? " ROOM DETAIL"
                              : "Zimmerdetails"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Accommodation;
