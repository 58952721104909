import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl";

const initialState = {
  allInclusive: {},
  loading: true,
  error: null,
};

export const fetchAllInclusive = createAsyncThunk(
  "allInclusive/fetchAllInclusive",
  async (hotelUrl) => {
    const response = await axios.get(
      `${baseUrl}/api/Restaurant/en/${hotelUrl}/AllInclusiveConcept`
    );
    return response.data;
  }
);

const allInclusiveSlice = createSlice({
  name: "allInclusive",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllInclusive.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllInclusive.fulfilled, (state, action) => {
        state.loading = false;
        state.allInclusive = action.payload;
      })
      .addCase(fetchAllInclusive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default allInclusiveSlice.reducer;
