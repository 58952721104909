import React, { useEffect } from "react";

// import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";
import { Fade, Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchPrivacy } from "../../data/privacySlice";
import Loader from "../../Loader";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { privacy, loading, error } = useSelector((state) => state.privacy);
  useEffect(() => {
    dispatch(fetchPrivacy(language));
  }, [dispatch, hotelUrl, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{privacy.pageMetatagTitle}</title>

            <meta name="description" content={privacy.pageMetatagDescription} />
          </Helmet>
          <section className="hero -type-10">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <Slide className="img-ratio" triggerOnce="true">
                        <img
                          src={
                            isMobile
                              ? privacy?.pageBannerMobile
                              : isTablet
                              ? privacy?.pageBannerTablet
                              : privacy?.pageBannerPC
                          }
                          alt={privacy?.pageTitle}
                          className="img-ratio"
                        />
                      </Slide>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up">
                          {" "}
                          <h1 className="hero__title capitalize text-white">
                            {privacy?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to="/"
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                {/*  <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              href="/material-ui/getting-started/installation/"
            >
              Sub
            </Link>*/}
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {privacy?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="first-section mt-50 " id="terms">
            <Container>
              <div className="page_content_wrap scheme_default">
                <div
                  className="content_wrap pt-50 "
                  dangerouslySetInnerHTML={{
                    __html: `${privacy.pageText}`,
                  }}
                />
              </div>
            </Container>
          </section>
        </>
      )}
    </>
  );
};
export default PrivacyPolicy;
