import React, { useRef } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faClock } from "@fortawesome/free-regular-svg-icons";
const MeetingAndEventsDetailsGroup = () => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  return (
    <>
      <section className="pageHero -type-2 -items-center">
        <div className="pageHero__bg">
          <Slide className="img-ratio" triggerOnce="true">
            {" "}
            <img src="banner-meeting.jpg" className="img-ratio" alt="" />
          </Slide>
        </div>

        <div className="container">
          <div className="row justify-center">
            <div className="col-12">
              <div className="pageHero__content text-center">
                <Fade direction="up">
                  <h1 className="pageHero__title lh-11 capitalize text-white">
                    Meetings
                  </h1>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-40 x-gap-60 justify-between">
            <div className="col-12 col-xl-8 col-lg-8">
              <Fade
                cascade
                direction="up"
                duration="500"
                delay="0"
                triggerOnce="true"
              >
                {" "}
                <h2 className="text-40">Meetings</h2>
                <div className="line -horizontal bg-border mt-50 mb-50"></div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic
                  <br />
                  <br />
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic
                </p>
              </Fade>
            </div>

            <div className="col-12 col-xl-4 col-lg-4">
              <Fade direction="right">
                <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                  <h2 className="text-40">Meetings & Events</h2>
                  <div className="row x-gap-50 y-gap-20 justify-between pt-40">
                    <div className="col-sm-12">
                      <ul>
                        <li>
                          <Link to="#.">
                            <FontAwesomeIcon
                              icon={faSquareArrowUpRight}
                              className="mr-10"
                            />
                            Weddings
                          </Link>
                        </li>
                        <hr style={{ color: "#ccc" }} />
                        <li>
                          <Link to="#.">
                            <FontAwesomeIcon
                              icon={faSquareArrowUpRight}
                              className="mr-10"
                            />
                            Private Events
                          </Link>
                        </li>
                        <hr style={{ color: "#ccc" }} />
                      </ul>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="line -horizontal bg-border mt-50 mb-50"></div>
        </div>
      </section>
      <section className="layout-pt-lg layout-pb-lg bg-light-1">
        <div className="container">
          <div className="row justify-center text-center mb-100 sm:mb-50">
            <div className="col-auto">
              <div className="text-15 uppercase mb-30 sm:mb-10">EXPLORE</div>
              <h2 className="text-64 md:text-40">Gallery</h2>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-10 col-md-11">
                <Swiper
                  className="overflow-visible"
                  modules={[Navigation]}
                  spaceBetween={180}
                  slidesPerView={1}
                  onSwiper={(swiper) => (swiperRef.current = swiper)}
                  navigation={{
                    prevEl: ".js-slider1-prev",
                    nextEl: ".js-slider1-next",
                  }}
                >
                  {[
                    "roomgallery1.jpg",
                    "roomgallery2.jpg",
                    "roomgallery3.jpg",
                    "roomgallery4.jpg",
                  ].map((src, index) => (
                    <SwiperSlide key={index}>
                      <div className="ratio ratio-16:9">
                        <img src={src} alt="" className="img-ratio" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>

          <div className="navAbsolute -type-3">
            <button
              className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-prev"
              onClick={handlePrev} // Call handlePrev when prev button is clicked
            >
              <i className="icon-arrow-left text-24 text-white"></i>
            </button>
            <button
              className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-next"
              onClick={handleNext} // Call handleNext when next button is clicked
            >
              <i className="icon-arrow-right text-24 text-white"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default MeetingAndEventsDetailsGroup;
