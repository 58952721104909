import {
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faGlobeAsia,
  faHome,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Link, Outlet, useLocation } from "react-router-dom";
import Loader from "./../Loader";

import { useDispatch, useSelector } from "react-redux";
import { fetchLayoutHotel } from "../data/layoutHotelSlice";
import axios from "axios";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Alert, Snackbar } from "@mui/material";
import { setLanguage } from "../data/languageSlice";

const LayoutHotels = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");

  const { layoutHotel, loading } = useSelector((state) => state.layoutHotel);
  const { hotels } = useSelector((state) => state.hotels);
  const [openLangMen, setOpenLangMenu] = useState(false);
  const [openHotelMen, setOpenHotelMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // This could be expanded to manage the actual language selection in your app
  const language = useSelector((state) => state.language.language);
  const [hotelDrop, setHotelDrop] = useState("Hotels");
  const [addClass, setAddClass] = useState(false);
  const [viewDining, setViewDining] = useState(false);
  const toggleDining = () => {
    setViewDining(!viewDining);
  };
  const handleScroll = () => {
    const offset = window.scrollY;
    const threshold = 100; // Change this value to whatever suits your needs
    if (offset > threshold) {
      setAddClass(true);
    } else {
      setAddClass(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setVisible(false); // Hide header on scroll down
      } else {
        setVisible(true); // Show header on scroll up
      }
      setLastScrollY(currentScrollY);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLayoutHotel({ hotelUrl, language }));
  }, [dispatch, hotelUrl, language]);
  useEffect(() => {
    const hotelUrl = localStorage.getItem("hotelUrl");
    // Get the current URL
    if (!hotelUrl) {
      window.location.href = `/en`;
    }
  }, [hotelUrl]);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [errorOperation, setErrorOperation] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(
        `https://api.hwaidakhotels.com/api/ContactUs/Newsletter-Submit?request=${email}`
      );
      setSuccessOperation(true);
      setEmail("");
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessOperation(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOperation(false);
    setErrorOperation(false);
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const customLanguages = layoutHotel.hotelHeader?.languages
    ?.map((lang) => lang)
    .filter(
      (abbrev) =>
        abbrev.languageAbbreviation != "xx" &&
        abbrev.languageAbbreviation != language
    );

  const toggleLanguage = (selectedLanguage) => {
    const newLanguage = selectedLanguage.languageAbbreviation;
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={`menuFullScreen   ${isMenuOpen ? "is-active" : ""}`}>
            <div className="menuFullScreen__topMobile py-0 ">
              <div className="d-flex align-items-center justify-content-between col-12 py-20">
                <div
                  className="d-flex items-center text-white  "
                  onClick={toggleMenu}
                >
                  <i className="icon-menu text-9"></i>
                  <div className="text-15 uppercase ml-30 sm:d-none">
                    {language === "ru"
                      ? "Меню"
                      : language === "en"
                      ? "Menu"
                      : "Menü"}{" "}
                  </div>
                </div>
                <div className="language-selector  ">
                  <div
                    className="d-flex"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenHotelMenu(!openHotelMen)}
                  >
                    <button className="button text-white language-button d-flex align-items-baseline">
                      <FontAwesomeIcon
                        icon={hotelDrop === "Hotels" ? faHome : ""}
                        className="pr-5"
                        style={{ fontSize: "13px" }}
                      />{" "}
                      {` ${hotelDrop}`}
                    </button>
                    {!openHotelMen ? (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                  <div
                    className="language-options px-10 py-10 w-100"
                    style={{
                      minWidth: "200px",
                      display: openHotelMen ? "block" : "none",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      className="d-flex align-items-center justify-content-start mt-5 mb-5"
                      onClick={() => {
                        localStorage.removeItem("hotelUrl");
                        setHotelDrop(
                          language === "ru"
                            ? "Отели"
                            : language === "en"
                            ? "Hotels"
                            : "Hotels"
                        );
                        setOpenHotelMenu(false);
                        setIsMenuOpen(false);
                      }}
                      to="/"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="pr-5"
                        style={{ fontSize: "13px" }}
                      />
                      {language === "ru"
                        ? "Отели"
                        : language === "en"
                        ? "Hotels"
                        : "Hotels"}
                    </Link>
                    {hotels.map((hotel, index) => (
                      <Link
                        key={index}
                        className="d-flex align-items-center justify-content-start mb-5"
                        onClick={() => {
                          localStorage.setItem("hotelUrl", hotel.hotelUrl);
                          setHotelDrop(hotel.hotelName);
                          setOpenHotelMenu(false);
                          setIsMenuOpen(false);
                        }}
                        to={`/${hotel.hotelUrl}`}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="pr-5"
                          style={{ fontSize: "13px" }}
                        />{" "}
                        {hotel.hotelName}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="menuFullScreen__mobile__bg  "></div>

            <div className="menuFullScreen__left">
              <div className="menuFullScreen-links  " style={{ gap: "20px" }}>
                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to={`/${hotelUrl}`}
                  >
                    {language === "ru"
                      ? "На главную"
                      : language === "en"
                      ? "Home"
                      : "Startseite"}
                  </Link>
                </div>

                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to={`/${hotelUrl}/accommodation`}
                  >
                    {language === "ru"
                      ? "Номера"
                      : language === "en"
                      ? "Accommodation"
                      : "Unterkunft"}
                  </Link>
                </div>
                <div className="menuFullScreen-links__item js-menuFullScreen-has-children">
                  <Link to={`#.`} onClick={toggleDining}>
                    {language === "ru"
                      ? "Питание"
                      : language === "en"
                      ? "Dining"
                      : "Gastronomie"}
                    {layoutHotel.hotelHeader.diningTypes?.length > 0 && (
                      <i
                        className={`icon-arrow-right ${
                          viewDining
                            ? "mobile-transform-top"
                            : "mobile-transform-bottom"
                        }`}
                      ></i>
                    )}
                    <i
                      className={`icon-chevron-right ${
                        viewDining
                          ? "mobile-transform-top"
                          : "mobile-transform-bottom"
                      }`}
                    ></i>
                  </Link>
                  {layoutHotel.hotelHeader.diningTypes?.length > 0 && (
                    <div
                      className="menuFullScreen-links-subnav js-menuFullScreen-subnav"
                      style={{ maxHeight: viewDining && "unset" }}
                    >
                      <div className="menuFullScreen-links-subnav__item">
                        {layoutHotel.hotelHeader.diningTypes?.map((dining) => (
                          <div
                            className="menuFullScreen-links-subnav__item mb-10 mb-lg-0"
                            key={dining.filterBy}
                          >
                            <Link
                              to={`/${dining.hotelUrl}/${dining.filterBy}`}
                              onClick={() => setIsMenuOpen(!isMenuOpen)}
                            >
                              {dining.restaurantsTypeName}
                            </Link>
                          </div>
                        ))}
                        {layoutHotel.isEnableAllInclusive && (
                          <div
                            className="menuFullScreen-links-subnav__item"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                          >
                            <Link to={`/${hotelUrl}/all-inclusive`}>
                              {language === "ru"
                                ? "все включено"
                                : language === "en"
                                ? "All Inclusive"
                                : "Alles inklusive"}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to={`/${hotelUrl}/facilities`}
                  >
                    {language === "ru"
                      ? "Услуги"
                      : language === "en"
                      ? "Facilities"
                      : "Einrichtungen"}
                  </Link>
                </div>

                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to={`/${hotelUrl}/meetingandevents`}
                  >
                    {language === "ru"
                      ? "Конференции и специальные мероприятия"
                      : language === "en"
                      ? "Meetings & Events"
                      : "Tagungen und Veranstaltungen"}
                  </Link>
                </div>
                {layoutHotel.hotelHeader?.isEnableSpa && (
                  <div className="menuFullScreen-links__item">
                    <Link
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                      to={`/${hotelUrl}/spa`}
                    >
                      {language === "ru"
                        ? "СПА"
                        : language === "en"
                        ? "SPA"
                        : "SPA"}
                    </Link>
                  </div>
                )}

                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to={`/${hotelUrl}/gallery`}
                  >
                    {language === "ru"
                      ? "Галерея"
                      : language === "en"
                      ? "Gallery"
                      : "Galerie"}
                  </Link>
                </div>
                <div className="menuFullScreen-links__item">
                  <Link
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                    to={`/${hotelUrl}/contact-us`}
                  >
                    {language === "ru"
                      ? "Свяжитесь с нами"
                      : language === "en"
                      ? "Contact Us"
                      : "Kontakt"}
                  </Link>
                </div>
              </div>
            </div>

            <hr className="text-light bg-light col-10 mx-auto" />
            <div className="menuFullScreen__bottomMobile js-menuFullScreen-buttomMobile  ">
              <div className="text-center">
                <div className="mt-0">
                  <div className="text-30 text-sec fw-500">
                    {" "}
                    {language === "ru"
                      ? "Свяжитесь с нами"
                      : language === "en"
                      ? "Contact Us"
                      : "Kontakt"}
                  </div>
                  <div className="mt-20">
                    <Link to={`tel:${layoutHotel.hotelHeader?.hotelPhone}`}>
                      {layoutHotel.hotelHeader?.hotelPhone}{" "}
                    </Link>
                  </div>
                  <div className="mt-10">
                    <Link to={`mailto:${layoutHotel.hotelHeader?.hotelEmail}`}>
                      {layoutHotel.hotelHeader?.hotelEmail}
                    </Link>
                  </div>
                  <div className="mt-10">
                    <Link to="#.">{layoutHotel.hotlFooter?.hotelAddress}</Link>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>

          <header
            className={`header -mx-60 -bottomMenu js-header ${
              addClass ? "bg-accent-1" : ""
            } ${visible ? "" : "hidden"}`}
            data-add-bg="bg-accent-1"
            data-x="header"
            data-x-toggle={isMenuOpen ? "-is-menu-opened" : ""}
          >
            <div className="header__container py-30 items-center">
              <div className="header__left d-flex items-center">
                <div
                  className="items-center d-none lg:d-flex js-menuFullScreen-toggle"
                  onClick={toggleMenu}
                >
                  <i className="icon-menu text-9 text-white"></i>
                  <div className="text-15 uppercase text-white ml-30 sm:d-none">
                    {language === "ru"
                      ? "Меню"
                      : language === "en"
                      ? "Menu"
                      : "Menü"}
                  </div>
                </div>

                <Link
                  to="tel:+201234567890"
                  className="d-flex items-center mr-20 xl:d-none"
                >
                  <i className="icon-phone text-20 text-white mr-10"></i>
                  <div className="text-15 uppercase text-white">
                    {layoutHotel.hotelHeader?.hotelPhone}{" "}
                  </div>
                </Link>

                <Link
                  to={`mailto:${layoutHotel.hotelHeader?.hotelEmail}`}
                  className="d-flex items-center text-white ml-30 lg:d-none"
                >
                  <i className="icon-email text-20 mr-10"></i>
                  {layoutHotel.hotelHeader?.hotelEmail}
                </Link>
              </div>

              <div className="header__center">
                <Link to={`/${hotelUrl}`} className="header__logo">
                  <img src={layoutHotel.hotelHeader?.hotelLogo} alt="logo" />
                </Link>
              </div>

              <div className="header__right d-flex items-center h-full ">
                <div className="language-selector me-lg-4  sm:d-none">
                  <div
                    className="d-flex"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenHotelMenu(!openHotelMen)}
                  >
                    <button className="button text-white language-button d-flex align-items-baseline">
                      <FontAwesomeIcon
                        icon={faHome}
                        className="pr-5"
                        style={{ fontSize: "13px" }}
                      />
                      {language === "ru"
                        ? "Отели"
                        : language === "en"
                        ? "Hotels"
                        : "Hotels"}
                    </button>
                    {!openHotelMen ? (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                  <div
                    className="language-options px-10 py-10 w-100"
                    style={{
                      minWidth: "200px",
                      display: openHotelMen ? "block" : "none",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      className="d-flex align-items-center justify-content-start mt-5 mb-5"
                      onClick={() => {
                        localStorage.removeItem("hotelUrl");
                        setHotelDrop("Hotels");
                        setOpenHotelMenu(false);
                      }}
                      to="/"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="pr-5"
                        style={{ fontSize: "12px" }}
                      />
                      {language === "ru"
                        ? "Отели cети Hwaidak"
                        : language === "en"
                        ? "Hwaidak Hotels"
                        : "Hwaidak Hotels"}
                    </Link>
                    {hotels.map((hotel, index) => (
                      <Link
                        key={index}
                        className="d-flex align-items-center justify-content-start mb-5"
                        onClick={() => {
                          localStorage.setItem("hotelUrl", hotel.hotelUrl);
                          setHotelDrop(hotel.hotelName);
                          setOpenHotelMenu(false);
                        }}
                        to={`/${hotel.hotelUrl}`}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="pr-5"
                          style={{ fontSize: "12px" }}
                        />{" "}
                        {hotel.hotelName}
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="language-selector  "
                  // style={{ visibility: "hidden" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenLangMenu(!openLangMen)}
                  >
                    <button
                      className="button text-white language-button  d-flex align-items-center "
                      style={{ textTransform: "uppercase" }}
                    >
                      <FontAwesomeIcon
                        className="mr-5 text-light"
                        style={{ fontSize: "16px" }}
                        icon={faGlobeAsia}
                      />{" "}
                      {language}
                    </button>
                    {!openLangMen ? (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="ml-5 text-light"
                        style={{ fontSize: "13px", paddingTop: "2px" }}
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                  <div
                    className="language-options "
                    style={{
                      display: openLangMen ? "block" : "none",
                      cursor: "pointer",
                      minWidth: "125px",
                    }}
                  >
                    {customLanguages?.map((lang, index) => (
                      <div
                        key={index}
                        className="language-option d-flex align-items-center"
                        onClick={() => {
                          setOpenLangMenu(false);
                          toggleLanguage(lang);
                        }}
                      >
                        <img
                          alt="flag"
                          className="mr-10"
                          src={lang.languageFlag}
                        />
                        {lang.languageName}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-center text-white w-1/1 border-bottom-white-20 border-top-white-20 py-10 md:d-none">
              <div className="desktopNav">
                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}`}>
                    {language === "ru"
                      ? "На главную"
                      : language === "en"
                      ? "Home"
                      : "Startseite"}
                  </Link>
                </div>

                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}/accommodation`}>
                    {language === "ru"
                      ? "Номера"
                      : language === "en"
                      ? "Accommodation"
                      : "Unterkunft"}
                  </Link>
                </div>

                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}/dining`}>
                    {language === "ru"
                      ? "Питание"
                      : language === "en"
                      ? "Dining"
                      : "Gastronomie"}
                    {layoutHotel.hotelHeader.diningTypes?.length > 0 && (
                      <i className="icon-chevron-down"></i>
                    )}
                  </Link>
                  {layoutHotel.hotelHeader.diningTypes?.length > 0 && (
                    <div className="desktopNavSubnav">
                      <div className="desktopNavSubnav__content">
                        {layoutHotel.hotelHeader.diningTypes?.map((dining) => (
                          <div
                            className="desktopNav__item"
                            key={dining.filterBy}
                          >
                            <Link to={`/${dining.hotelUrl}/${dining.filterBy}`}>
                              {dining.restaurantsTypeName}
                            </Link>
                          </div>
                        ))}
                        {layoutHotel.isEnableAllInclusive && (
                          <div className="desktopNav__item">
                            <Link to={`/${hotelUrl}/all-inclusive`}>
                              {language === "ru"
                                ? "все включено"
                                : language === "en"
                                ? "All Inclusive"
                                : "Alles inklusive"}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}/facilities`}>
                    {language === "ru"
                      ? "Услуги"
                      : language === "en"
                      ? "Facilities"
                      : "Einrichtungen"}
                  </Link>
                </div>

                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}/meetingandevents`}>
                    {language === "ru"
                      ? "Конференции и специальные мероприятия"
                      : language === "en"
                      ? "Meetings & Events"
                      : "Tagungen und Veranstaltungen"}
                  </Link>
                </div>
                {layoutHotel.hotelHeader?.isEnableSpa && (
                  <div className="desktopNav__item">
                    <Link to={`/${hotelUrl}/spa`}>
                      {language === "ru"
                        ? "СПА"
                        : language === "en"
                        ? "SPA"
                        : "SPA"}
                    </Link>
                  </div>
                )}

                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}/gallery`}>
                    {language === "ru"
                      ? "Галерея"
                      : language === "en"
                      ? "Gallery"
                      : "Galerie"}
                  </Link>
                </div>

                <div className="desktopNav__item">
                  <Link to={`/${hotelUrl}/contact-us`}>
                    {" "}
                    {language === "ru"
                      ? "Свяжитесь с нами"
                      : language === "en"
                      ? "Contact Us"
                      : "Kontakt"}
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <main id="main_header">
            <Outlet />
          </main>
          <Link
            to="#"
            onClick={() => scrollToSection("main_header")}
            style={{
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              textAlign: "center",
              padding: "0px",
              display: "block",
              position: "fixed",
              display: scrolled ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              bottom: "20px",
              right: "30px",
              zIndex: 999999,
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                padding: "0px",
                color: "#242c7d",

                borderRadius: "50%",
              }}
            />
          </Link>
          <footer className="footer -type-1 bg-accent-1 text-white   ">
            <div className="footer__main ">
              <div className="container ">
                <div className="row d-flex justify-content-between align-items-baseline mx-auto col-lg-11">
                  {/* <div className="col-lg-3">
                    <h4 className="text-30 fw-500 text-white">
                      {" "}
                      {layoutHotel.hotlFooter?.hotelLocationTitle}{" "}
                    </h4>

                    <div className="text-white-60 text-15 lh-17 mt-60 md:mt-20">
                      {layoutHotel.hotlFooter?.hotelLocation}
                    </div>
                  </div> */}

                  <div className="col-lg-4  mt-30 mt-lg-0">
                    <h4 className="text-30 fw-500 text-white">
                      {" "}
                      {language === "ru"
                        ? "Свяжитесь"
                        : language === "en"
                        ? "Contact"
                        : "Kontakt"}{" "}
                    </h4>

                    <div className="d-flex flex-column mt-50 md:mt-20">
                      <Link
                        className="d-block text-15 text-white-60 my-2"
                        to={`tel:${layoutHotel.hotlFooter?.hotelPhone}`}
                      >
                        <FontAwesomeIcon icon={faPhone} className="mr-5" />{" "}
                        {layoutHotel.hotlFooter?.hotelPhone}
                      </Link>
                      {layoutHotel.hotlFooter?.hotelWhatsAppNumber && (
                        <a
                          className="d-block text-15 text-white-60 my-2"
                          href={`https://wa.me/${layoutHotel.hotlFooter?.hotelWhatsAppNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="mr-5 fs-5"
                          />{" "}
                          {layoutHotel.hotlFooter?.hotelWhatsAppNumber}
                        </a>
                      )}

                      <Link
                        className="d-block text-15 text-white-60 my-2"
                        to={`mailto:${layoutHotel.hotlFooter?.hotelEmail}`}
                      >
                        <FontAwesomeIcon icon={faEnvelope} className="mr-5" />{" "}
                        {layoutHotel.hotlFooter?.hotelEmail}
                      </Link>

                      <Link
                        className="d-block text-15 text-white-60 lh-17"
                        to={layoutHotel.hotlFooter?.hotelGetDirectionLink}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="mr-5"
                        />{" "}
                        <span className="col-11">
                          {layoutHotel.hotlFooter?.hotelAddress}
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4  mt-30 mt-lg-0">
                    <h4 className="text-30 fw-500 text-white">
                      {" "}
                      {language === "ru"
                        ? "Ссылки"
                        : language === "en"
                        ? "Links"
                        : "Links"}{" "}
                    </h4>

                    <div className="row x-gap-50 y-gap-15">
                      <div className="col-sm-6">
                        <div className="y-gap-15 text-15 text-white-60 mt-50 md:mt-0">
                          <Link
                            className="d-block"
                            to={`/${hotelUrl}/accommodation`}
                          >
                            {language === "ru"
                              ? "Номера"
                              : language === "en"
                              ? "Accommodation"
                              : "Unterkunft"}
                          </Link>

                          <Link className="d-block" to={`/${hotelUrl}/dining`}>
                            {language === "ru"
                              ? "Питание"
                              : language === "en"
                              ? "Dining"
                              : "Gastronomie"}
                          </Link>
                          {layoutHotel.isEnableAllInclusive && (
                            <Link to={`/${hotelUrl}/all-inclusive`}>
                              {language === "ru"
                                ? "все включено"
                                : language === "en"
                                ? "All Inclusive"
                                : "Alles inklusive"}
                            </Link>
                          )}
                          <Link
                            className="d-block"
                            to={`/${hotelUrl}/facilities`}
                          >
                            {language === "ru"
                              ? "Услуги"
                              : language === "en"
                              ? "Facilities"
                              : "Einrichtungen"}
                          </Link>
                          <Link
                            className="d-block"
                            to={`/${hotelUrl}/meetingandevents`}
                          >
                            {language === "ru"
                              ? "Конференции и специальные мероприятия"
                              : language === "en"
                              ? "Meetings & Events"
                              : "Tagungen und Veranstaltungen"}
                          </Link>
                          <Link className="d-block" to={`/${hotelUrl}/gallery`}>
                            {language === "ru"
                              ? "Галерея"
                              : language === "en"
                              ? "Gallery"
                              : "Galerie"}
                          </Link>
                          {layoutHotel.hotlFooter?.isEnableSpa && (
                            <Link className="d-block" to={`/${hotelUrl}/spa`}>
                              {language === "ru"
                                ? "СПА"
                                : language === "en"
                                ? "SPA"
                                : "SPA"}
                            </Link>
                          )}
                          {layoutHotel.hotlFooter.isEnableFaq && (
                            <Link className="d-block" to={`/${hotelUrl}/faq`}>
                              {language === "ru"
                                ? "Часто задаваемые вопросы"
                                : language === "en"
                                ? "FAQ"
                                : "FAQ"}
                            </Link>
                          )}

                          <Link
                            className="d-block"
                            to={`/${hotelUrl}/contact-us`}
                          >
                            {language === "ru"
                              ? "Свяжитесь с нами"
                              : language === "en"
                              ? "Contact Us"
                              : "Kontakt"}
                          </Link>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="y-gap-15 text-15 text-white-60 mt-50 md:mt-0">
                          <Link className="d-block" to="/">
                            {language === "ru"
                              ? "Отели cети Hwaidak"
                              : language === "en"
                              ? "Hwaidak Hotels"
                              : "Hwaidak Hotels"}
                          </Link>

                          <Link className="d-block" to={`/online-check-in`}>
                            {language === "ru"
                              ? "Онлайн регистрация"
                              : language === "en"
                              ? "Online Check In"
                              : "Online-Check-in"}
                          </Link>
                          <Link className="d-block" to="/privacy-policy">
                            {language === "ru"
                              ? "Онлайн регистрация"
                              : language === "en"
                              ? "Privacy Policy"
                              : "Datenschutzerklärung"}
                          </Link>

                          <Link className="d-block" to="/terms-conditions">
                            {language === "ru"
                              ? "Условия"
                              : language === "en"
                              ? "Terms & Conditions"
                              : "Geschäftsbedingungen"}
                          </Link>

                          <Link className="d-block" to="contact-us#location">
                            {language === "ru"
                              ? "Получить направления/ Показать на карте (show on map)"
                              : language === "en"
                              ? "Get Directions"
                              : "Anweisungen bekommen"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4  mt-30 mt-lg-0">
                    <h4 className="text-30 fw-500 text-white">
                      {language === "ru"
                        ? "Подписаться на рассылку"
                        : language === "en"
                        ? "Newsletter Sign Up"
                        : "Newsletter Anmeldung"}{" "}
                    </h4>

                    <p className="text-15 text-white-60 mt-60 md:mt-20">
                      {language === "ru"
                        ? "Подпишитесь на нашу рассылку и получайте эксклюзивные предложения первыми"
                        : language === "en"
                        ? "Sign up for our news, deals and special offers."
                        : "Melden Sie sich für unsere Neuigkeiten, Deals und Sonderangebote an."}
                    </p>

                    <form
                      className="footer__newsletter mt-30"
                      onSubmit={handleSubmit}
                    >
                      <input
                        type="email"
                        placeholder={
                          language === "ru"
                            ? "ваш адрес электронной почты"
                            : language === "en"
                            ? "Your email address"
                            : "Ihre E-Mail-Adresse"
                        }
                        value={email}
                        onChange={handleChange}
                        required
                      />
                      {isSubmitting ? (
                        language === "ru" ? (
                          "Загрузка..."
                        ) : language === "en" ? (
                          "Loading..."
                        ) : (
                          "Wird geladen..."
                        )
                      ) : (
                        <button type="submit" disabled={isSubmitting}>
                          <i className="icon-arrow-right text-white text-20"></i>
                        </button>
                      )}
                    </form>
                    {errorOperation ? (
                      <>
                        <Snackbar
                          open={errorOperation}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {errorMessage}
                          </Alert>
                        </Snackbar>
                      </>
                    ) : (
                      <Snackbar
                        open={successOperation}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {language === "ru"
                            ? "Теперь вы подписаны. Спасибо!"
                            : language === "en"
                            ? "You Are Subscribed Now. Thank You!"
                            : "Sie sind jetzt angemeldet. Danke!"}
                        </Alert>
                      </Snackbar>
                    )}
                    <div className="row x-gap-25 y-gap-10 items-center justify-center pt-20">
                      {layoutHotel.hotlFooter?.hotelSocials.map(
                        (social, index) => (
                          <div
                            className={`${
                              social?.socialUrl.includes("holidaycheck")
                                ? "px-0"
                                : ""
                            } col-auto`}
                            key={index}
                          >
                            {social.socialUrl && (
                              <>
                                <Link
                                  to={social.socialUrl}
                                  title={social.socialName}
                                  target="_blank"
                                  className={`${
                                    social.socialClass ? "d-block" : "d-none"
                                  } text-white-60`}
                                >
                                  <i
                                    className={`${
                                      social.socialClass === "icon-tripadvisor"
                                        ? "fa fa-tripadvisor"
                                        : social.socialClass
                                    } text-11`}
                                  ></i>
                                </Link>
                                {social?.socialUrl.includes("holidaycheck") && (
                                  <Link
                                    to={social.socialUrl}
                                    className="d-block text-left text-white-60"
                                  >
                                    <img
                                      src="Unternehmenslogo_HolidayCheck_AG_weiß.svg.png"
                                      width="80"
                                      alt="holidaycheck icon"
                                      style={{ filter: "invert(0.4)" }}
                                    />
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer__bottom">
              <div className="container">
                <div className="row y-gap-30 justify-between md:justify-center items-center mx-auto col-lg-11">
                  <div className="col-md-auto">
                    <div className="text-15 text-center text-white-60">
                      {layoutHotel.hotlFooter?.copyrights}
                    </div>
                  </div>

                  <div className="col-md-auto">
                    <div className="footer__bottom_center">
                      <Link to="/" className="d-flex justify-center">
                        <img
                          src={layoutHotel.hotlFooter?.groupLogo}
                          alt="logo"
                          title="Hwaidak Group"
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-md-auto">
                    <div className="row x-gap-25 y-gap-10 items-center justify-center   text-15 text-white-60 ">
                      <span className="text-center text-lg-end">
                        {" "}
                        {language === "ru"
                          ? "Питаться от"
                          : language === "en"
                          ? "Powered By"
                          : "Angetrieben von"}
                        <Link
                          to="https://titegypt.com/"
                          className="ml-10"
                          target="_blank"
                        >
                          T.I.T Solutions
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default LayoutHotels;
