import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeHotel } from "../data/homeHotel/homeHotelSlice";
import { Helmet } from "react-helmet";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

SwiperCore.use([Pagination, Navigation]);
const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};
const HomeHotel = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { homeHotel, loading, error } = useSelector((state) => state.homeHotel);
  useEffect(() => {
    dispatch(fetchHomeHotel({ hotelUrl, language }));
  }, [dispatch, hotelUrl, language]);

  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const updateActiveIndex = (index) => {
    setActiveIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  const tiltRef1 = useRef(null);

  const tiltHandler1 = createTiltHandler(tiltRef1);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>
              {homeHotel.metatagTitle ? homeHotel.metatagTitle : "Hotel"}
            </title>
            <meta
              name="description"
              content={
                homeHotel.metatagDescription ? homeHotel.metatagDescription : ""
              }
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <Swiper
              className="hero__slider js-section-slider"
              spaceBetween={0}
              slidesPerView={1}
              navigation={{
                prevEl: ".js-sliderHero-prev",
                nextEl: ".js-sliderHero-next",
              }}
              pagination={{
                clickable: true,
                el: ".js-sliderHero-pagination",
                bulletClass: "pagination__item",
                bulletActiveClass: "is-active",
                renderBullet: (index, className) => {
                  return `<div class="${className}" tabindex="0" role="button" aria-label="Go to slide ${
                    index + 1
                  }"></div>`;
                },
              }}
            >
              {homeHotel.sliders?.map((slider, index) => (
                <SwiperSlide key={index}>
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <img
                        src={slider.sliderPhoto}
                        alt={slider.sliderMainText}
                        className="img-ratio"
                      />
                    </div>
                    <div className="hero__content text-center">
                      <div>
                        <Fade cascade direction="up">
                          <div className="hero__subtitle text-white">
                            {slider.sliderSubText}
                          </div>
                          <h1 className="hero__title capitalize text-white">
                            {slider.sliderMainText}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="hero__pagination">
              <div className="pagination -type-1 -light js-sliderHero-pagination"></div>
            </div>
            <div className="hero__nav navAbsolute">
              <button className="button text-white size-80 flex-center rounded-full js-sliderHero-prev">
                <i className="icon-arrow-left text-24"></i>
              </button>
              <button className="button text-white size-80 flex-center rounded-full js-sliderHero-next">
                <i className="icon-arrow-right text-24"></i>
              </button>
            </div>
          </section>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row y-gap-50 px-lg-5 items-center justify-between">
                <Fade cascade className="d-block col-lg-6 col-md-9">
                  <div>
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {homeHotel.sectionWelcomeTitle1}
                    </div>
                    <h2 className="text-64 md:text-40 capitalize">
                      {homeHotel.sectionWelcomeTitle2}
                    </h2>
                    <p
                      className="lh-18 pr-60 lg:pr-0 mt-40 md:mt-30"
                      dangerouslySetInnerHTML={{
                        __html: homeHotel.sectionWelcomeTitleText,
                      }}
                    />

                    {/*<div className="d-flex mt-50 md:mt-40">
                      <button className="button -type-1">
                        <i className="-icon">
                          <svg
                            width="50"
                            height="30"
                            viewBox="0 0 50 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                              stroke="#122223"
                            ></path>
                            <path
                              d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                              fill="#122223"
                            ></path>
                          </svg>
                        </i>
                        READ MORE
                      </button>
                    </div> */}
                    <div className="row y-gap-30 pt-40 md:pt-40">
                      <div className="col-auto">
                        <div className="row justify-center is-in-view">
                          {homeHotel.hotelAwards?.map((award, index) => (
                            <div className="col-auto" key={index}>
                              <img
                                src={award.awardphoto}
                                alt={award.awardtitle}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>

                <div
                  className="col-lg-6"
                  ref={tiltRef1}
                  onMouseMove={tiltHandler1.handleMove}
                  onMouseOut={tiltHandler1.handleMouseOut}
                  onMouseDown={tiltHandler1.handleMouseDown}
                  onMouseUp={tiltHandler1.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade direction="right">
                    <img
                      src={homeHotel.hotelPhoto}
                      className="d-block ms-auto"
                      alt={homeHotel.sectionWelcomeTitleText}
                    />
                  </Fade>
                  {/*
            <div>
                <div className="imageGrid -type-3">

                <img
                src="intro2.jpg"
                alt=""
                
                />
                <img src="intro1.jpg" alt="" />
                </div>
                </div>
            */}
                </div>
              </div>
            </div>
          </section>
          <section className="layout-pt-lg layout-pb-lg bg-accent-1">
            <div className="container">
              <div className="row y-gap-30 justify-between items-end">
                <div className="col-auto">
                  <div className="text-15 uppercase text-white mb-30 sm:mb-10">
                    {homeHotel.sectionRoomTitleBack}
                  </div>
                  <h2 className="text-64 md:text-40 lh-065 text-white">
                    {homeHotel.sectionRoomTitle}
                  </h2>
                </div>

                <div className="col-auto">
                  <Link
                    className="button -type-1 text-white"
                    to={`/${homeHotel.hotelUrl}/accommodation`}
                  >
                    <i className="-icon">
                      <svg
                        width="50"
                        height="30"
                        viewBox="0 0 50 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                          stroke="#FFF"
                        ></path>
                        <path
                          d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                          fill="#FFF"
                        ></path>
                      </svg>
                    </i>{" "}
                    {language === "ru"
                      ? "Посмотреть все"
                      : language === "en"
                      ? "View All"
                      : "ALLE ANSEHEN"}
                  </Link>
                </div>
              </div>

              <div className="row y-gap-30 pt-100 sm:pt-50">
                <div className="col-xl-12">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={2}
                    navigation={{
                      nextEl: ".js-slider1-next",
                      prevEl: ".js-slider1-prev",
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      640: {
                        // For small screens
                        slidesPerView: 1,
                      },
                      768: {
                        // For medium screens
                        slidesPerView: 1,
                      },
                      1024: {
                        // For large screens
                        slidesPerView: 2,
                      },
                    }}
                    pagination={{
                      clickable: true,
                      el: ".js-slider1-pagination",
                      bulletClass: "pagination__item",
                      bulletActiveClass: "is-active",
                      renderBullet: (index, className) => {
                        return `<div class="${className}" tabindex="0" role="button" aria-label="Go to slide ${
                          index + 1
                        }"> </div>`;
                      },
                    }}
                  >
                    {homeHotel.hotelRooms?.map((room, index) => (
                      <SwiperSlide key={index}>
                        <Link
                          to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                          className="roomCard -type-1 -hover-button-center -image-border"
                        >
                          <div>
                            <div className="roomCard__image ratio ratio-76:62 -hover-button-center__wrap">
                              <Slide
                                triggerOnce={true}
                                duration="1500"
                                className="img-ratio"
                              >
                                <img
                                  src={room.roomPhoto}
                                  alt={room.roomName}
                                  className="img-ratio"
                                />
                              </Slide>
                              <div className="-image-border__item"></div>
                              <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                                <span className="text-15 fw-500 text-white">
                                  {language === "ru"
                                    ? "Подробнее"
                                    : language === "en"
                                    ? "More Details"
                                    : "Mehr Details"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="roomCard__content mt-30">
                            <div className="d-flex justify-between items-center">
                              <h3 className="roomCard__title text-40 md:text-30 text-white">
                                {room.roomName}
                              </h3>
                              {/*<div className="roomCard__price text-white">
                              {room.price}
                    </div>*/}
                            </div>
                            <div className="d-flex flex-wrap col-10 items-center x-gap-10 text-white pt-30 md:pt-20">
                              <div className="d-flex items-center text-light pr-30">
                                <i className="icon-size text-20 mr-10"></i>{" "}
                                {language === "ru"
                                  ? "Размер:"
                                  : language === "en"
                                  ? "Size:"
                                  : "Größe:"}{" "}
                                {room.roomSize}
                              </div>
                              <div className="d-flex items-center text-light pr-30">
                                <i className="icon-guest text-20 mr-10"></i>
                                {language === "ru"
                                  ? "Максимальное кол-во гостей:"
                                  : language === "en"
                                  ? "Max Occupancy:"
                                  : "Maximale Belegung: "}
                                {room.maxOccupancy}
                              </div>
                              <div className="d-flex items-center text-light pr-30">
                                <FontAwesomeIcon
                                  icon={faExpand}
                                  className="text-20 mr-10 text-light"
                                />{" "}
                                {language === "ru"
                                  ? "Вид:"
                                  : language === "en"
                                  ? "View:"
                                  : "Aussicht:"}{" "}
                                {room.roomView}
                              </div>
                              <div className="d-flex items-center text-light pr-30">
                                <i className="icon-bed-2 text-20 mr-10"></i>
                                {room.roomBed}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>

              <div className="row justify-between items-center pt-100 sm:pt-50">
                <div className="col-auto">
                  <button className="button -outline-white text-white size-80 md:size-60 flex-center rounded-full js-slider1-prev">
                    <i className="icon-arrow-left text-24 md:text-19"></i>
                  </button>
                </div>

                <div className="col-auto">
                  <div className="pagination -type-1 -light js-slider1-pagination"></div>
                </div>

                <div className="col-auto">
                  <button className="button -outline-white text-white size-80 md:size-60 flex-center rounded-full js-slider1-next">
                    <i className="icon-arrow-right text-24 md:text-19"></i>
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <Fade cascade>
                  {" "}
                  <div className="col-auto">
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {language === "ru"
                        ? "Наши сервисы:"
                        : language === "en"
                        ? "OUR SERVICES"
                        : "UNSERE DIENSTLEISTUNGEN"}{" "}
                    </div>
                    <h2 className="text-64 md:text-40">
                      {language === "ru"
                        ? "Самые популярные сервисы и услуги"
                        : language === "en"
                        ? "Most Popular Facilities"
                        : "Beliebteste Einrichtungen"}
                    </h2>
                  </div>
                </Fade>
              </div>

              <div className="row y-gap-40 justify-between pt-100 sm:pt-50">
                {homeHotel.hotelServices?.map((service, index) => (
                  <Fade
                    cascade
                    className="col-lg-auto col-md-4 col-6"
                    key={index}
                  >
                    <div className="iconCard -type-1 -hover-1 text-center">
                      <div className="iconCard__icon text-50">
                        <div className="iconCard__icon__circle bg-light-1"></div>

                        <img
                          alt={service.seviceName}
                          src={service.serviceIcon}
                          width="45"
                          height="45"
                        />
                      </div>
                      <h4 className="text-24 sm:text-21 lh-1 mt-30 sm:mt-15">
                        {service.seviceName}
                      </h4>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>
          </section>
          <section className="layout-pt-lg layout-pb-lg bg-light-1">
            <div className="container">
              <div className="row y-gap-30 justify-between items-end">
                <div className="col-auto">
                  <Fade cascade direction="up" triggerOnce>
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {homeHotel.sectionActivitiesTitle}
                    </div>
                    <h2 className="text-64 md:text-40">
                      {homeHotel.sectionActivitiesText}
                    </h2>
                  </Fade>
                </div>

                <div className="col-auto">
                  <div className="pagination -type-1 -accent-1">
                    {homeHotel.hotelFacilities?.map(
                      (_, index) =>
                        index <= 2 && (
                          <div
                            key={index}
                            className={`pagination__item ${
                              activeIndex === index ? "is-active" : ""
                            }`}
                            tabIndex={1}
                            role="button"
                            aria-label={`Go to slide ${index}`}
                            aria-current={
                              activeIndex === index ? "true" : "false"
                            }
                            onClick={() => updateActiveIndex(index)}
                          ></div>
                        )
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-100 sm:pt-50">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={30}
                  slidesPerView={3}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    640: {
                      // For small screens
                      slidesPerView: 1,
                    },
                    768: {
                      // For medium screens
                      slidesPerView: 1,
                    },
                    1024: {
                      // For large screens
                      slidesPerView: 3,
                    },
                  }}
                  onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                  onSwiper={(swiper) => (swiperRef.current = swiper)}
                >
                  {homeHotel.hotelFacilities?.map((facility) => (
                    <SwiperSlide key={facility.facilityUrl}>
                      <div className="baseCard -type-1 -padding-lg">
                        <div
                          className={`baseCard__image ratio ratio-45:54 rounded-16`}
                        >
                          <Slide
                            className="img-ratio rounded-16"
                            triggerOnce="true"
                          >
                            <img
                              src={facility.facilityPhotoHome}
                              alt={facility.facilityName}
                              className="img-ratio rounded-16"
                            />
                          </Slide>
                        </div>
                        <Link
                          to={`${
                            facility.isMoreDetails
                              ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                              : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                          }`}
                          className="baseCard__content d-flex flex-column justify-end text-center"
                        >
                          <div
                            className={`${facility.facilityClassIcon} text-40 text-white mb-20`}
                          ></div>
                          <h4 className="text-30 md:text-25 text-white">
                            {facility.facilityName}
                          </h4>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
          {/*  <section className="layout-pt-lg layout-pb-lg">
         <div className="container animated">
           <div className="row y-gap-30 justify-between items-end">
             <div className="col-auto">
               <Fade cascade direction="up" triggerOnce="true">
                 {" "}
                 <div className="text-15 uppercase mb-30 sm:mb-10">
                   BLOG{" "}
                 </div>
                 <h2 className="text-64 md:text-40">
                   {homeHotel.sectionNewsTitle}
                 </h2>
               </Fade>
             </div>

             <div className="col-auto is-in-view">
               <Fade cascade direction="up" delay="100" triggerOnce="true">
                
               </Fade>
             </div>
           </div>

           <div className="row y-gap-30 x-gap-85 justify-between pt-100 sm:pt-50">
             {homeHotel.hotelNews?.map((item, index) => (
               <div className="col-lg-4 col-md-6" key={index}>
                 <Link to="#." className="baseCard -type-2">
                   <div className="baseCard__image ratio ratio-41:50 is-in-view">
                     <Slide
                       className="img-ratio"
                       triggerOnce="true"
                       duration="1500"
                     >
                       <img
                         src={item.newsPhoto}
                         alt={`news ${index + 1}`}
                         className="img-ratio"
                       />
                     </Slide>
                   </div>

                   <div className="baseCard__content mt-30 is-in-view">
                     <div className="row x-gap-10">
                       <div className="col-auto">{item.newsDateTime}</div>
                     </div>

                     <h4 className="text-30 md:text-25 mt-15">
                       {item.newsShortDescription}
                     </h4>

                     <div className="d-flex mt-20">
                       <Link to="#." className="button -type-1">
                         <i className="-icon">
                           <svg
                             width="50"
                             height="30"
                             viewBox="0 0 50 30"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                           >
                             <path
                               d="M35.8 28.0924C43.3451 28.0924 49.4616 21.9759 49.4616 14.4308C49.4616 6.88577 43.3451 0.769287 35.8 0.769287C28.255 0.769287 22.1385 6.88577 22.1385 14.4308C22.1385 21.9759 28.255 28.0924 35.8 28.0924Z"
                               stroke="#122223"
                             ></path>
                             <path
                               d="M33.4808 10.2039L32.9985 10.8031L37.2931 14.2623H0.341553V15.0315H37.28L33.0008 18.4262L33.4785 19.0285L39 14.6492L33.4808 10.2039Z"
                               fill="#122223"
                             ></path>
                           </svg>
                         </i>
                         READ MORE
                       </Link>
                     </div>
                   </div>
                 </Link>
               </div>
             ))}
           </div>
         </div>
       </section> */}
        </>
      )}
    </>
  );
};

export default HomeHotel;
