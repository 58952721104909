import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { fetchMeetingDetails } from "../../data/meetings/meetingDetailsSlice";
import { Helmet } from "react-helmet";
import { Button, Form, Modal } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const MeetingAndEventsDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");

  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const { facilityUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { meetingDetails, loading, error } = useSelector(
    (state) => state.meetingDetails
  );

  useEffect(() => {
    dispatch(fetchMeetingDetails({ hotelUrl, facilityUrl, language }));
  }, [dispatch, facilityUrl, hotelUrl, language]);
  const [value, setValue] = useState(dayjs());
  const [open, setOpen] = React.useState(false);

  const handleClickMsg = () => {
    setOpen(true);
  };

  const handleCloseMsg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    telephoneNumber: "",
    hotel: hotelUrl,
    eventStartDate: dayjs(),
    eventEndDate: dayjs(),
    specialRequest: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://api.hwaidakhotels.com/api/ContactUs/MeetingEvents-Submit?hotelUrl=${formData.hotel}`,
        {
          ...formData,
          eventStartDate: formData.eventStartDate.toISOString(),
          eventEndDate: formData.eventEndDate.toISOString(),
        }
      );
      // Reset form fields
      setFormData({
        firstName: "",
        lastName: "",
        emailAddress: "",
        telephoneNumber: "",
        hotel: "",
        eventStartDate: dayjs(),
        eventEndDate: dayjs(),
        specialRequest: "",
      });
      setOpen(true);
      setShow(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setOpen(false);
    }
  };
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const setSwiperRef2 = (swiper) => {
    swiperRef2.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <>
      {meetingDetails.isMoreDetails == true ? (
        <>
          <Helmet>
            <title>{meetingDetails.metatagTitle}</title>
            <meta
              name="description"
              content={meetingDetails.metatagDescription}
            />
          </Helmet>
          <section className="pageHero -type-2 -items-center">
            <div className="pageHero__bg">
              <div className="img-ratio">
                <img
                  src={
                    isMobile
                      ? meetingDetails.facilityBannerMobile
                      : isTablet
                      ? meetingDetails.facilityBannerTablet
                      : meetingDetails.facilityBanner
                  }
                  className="img-ratio"
                  alt={meetingDetails.facilityName}
                />
              </div>
            </div>

            <div className="container">
              <div className="row justify-center">
                <div className="col-12">
                  <div className="pageHero__content text-center">
                    <Fade direction="up">
                      <h1 className="pageHero__title lh-11 capitalize text-white">
                        {meetingDetails.facilityName}
                      </h1>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/meetingandevents`}
                >
                  {language === "ru"
                    ? "Конференции и специальные мероприятия"
                    : language === "en"
                    ? "Meetings & Events"
                    : "Tagungen und Veranstaltungen"}
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {meetingDetails.facilityName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-md  ">
            <div className="container">
              <div className="row y-gap-40 x-gap-60 justify-between">
                <div className="col-12 col-xl-8 col-lg-8">
                  <Fade
                    cascade
                    direction="up"
                    duration="500"
                    delay="0"
                    triggerOnce="true"
                  >
                    {" "}
                    <h2 className="text-40"> {meetingDetails.facilityName}</h2>
                    <div className="line -horizontal bg-border mt-50 mb-50"></div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${meetingDetails.facilityDetails}`,
                      }}
                    />
                  </Fade>
                </div>

                <div className="col-12 col-xl-4 col-lg-4">
                  <Fade direction="right">
                    <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                      <p className="text-30">
                        {meetingDetails.facilityName}{" "}
                        {language === "ru"
                          ? "Функции:"
                          : language === "en"
                          ? "Features:"
                          : "Merkmale:"}
                      </p>
                      <hr style={{ opacity: "0.1" }} />
                      <div className="row x-gap-50 y-gap-20 justify-between pt-20">
                        <div className="col-sm-12">
                          <div className="d-flex flex-column flex-wrap x-gap-20  ">
                            <div className="d-flex items-center text-accent-1 pb-10">
                              {language === "ru"
                                ? "Размер:"
                                : language === "en"
                                ? "Size:"
                                : "Größe:"}{" "}
                              {meetingDetails.meetingSize}
                            </div>

                            <div className="d-flex items-center text-accent-1 pb-10">
                              {language === "ru"
                                ? "Ширина:"
                                : language === "en"
                                ? "Widths:"
                                : "Breiten:"}{" "}
                              {meetingDetails.meetingWidths}
                            </div>

                            <div className="d-flex items-center text-accent-1 pb-10">
                              {language === "ru"
                                ? "Длина:"
                                : language === "en"
                                ? "Length:"
                                : "Länge:"}{" "}
                              {meetingDetails.meetingLength}
                            </div>

                            <div className="d-flex items-center text-accent-1 pb-10">
                              {language === "ru"
                                ? "Высота потолка:"
                                : language === "en"
                                ? "Celling Height:"
                                : "Deckenhöhe:"}{" "}
                              {meetingDetails.meetingCellingHeight}
                            </div>

                            <div className="d-flex items-center text-accent-1 pb-10">
                              {language === "ru"
                                ? "Вместительность:"
                                : language === "en"
                                ? "Capacity:"
                                : "Kapazität:"}{" "}
                              {meetingDetails.meetingCapacity}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="line -horizontal bg-border mt-50 mb-50"></div>
            </div>
          </section>
          {meetingDetails.meetingEventGallery?.length > 0 && (
            <section className="layout-pt-lg layout-pb-lg bg-light-1">
              <div className="container">
                <div className="row justify-center text-center mb-100 sm:mb-50">
                  <div className="col-auto">
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {language === "ru"
                        ? "Изучить"
                        : language === "en"
                        ? "EXPLORE"
                        : "ERKUNDEN"}
                    </div>
                    <h2 className="text-64 md:text-40">
                      {language === "ru"
                        ? "Галерея"
                        : language === "en"
                        ? "Gallery"
                        : "Galerie"}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="container">
                  <div className="row justify-center">
                    <div className="col-lg-10 col-md-11">
                      <Swiper
                        className="overflow-visible"
                        modules={[Navigation]}
                        spaceBetween={180}
                        slidesPerView={1}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                      >
                        {meetingDetails.meetingEventGallery?.map(
                          (image, index) => (
                            <SwiperSlide key={index}>
                              <div className="ratio ratio-16:9">
                                <img
                                  src={image.photoFile}
                                  alt={`meeting ${index + 1}`}
                                  className="img-ratio"
                                />
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>

                <div className="navAbsolute -type-3">
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-prev"
                    onClick={handlePrev} // Call handlePrev when prev button is clicked
                  >
                    <i className="icon-arrow-left text-24 text-white"></i>
                  </button>
                  <button
                    className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-next"
                    onClick={handleNext} // Call handleNext when next button is clicked
                  >
                    <i className="icon-arrow-right text-24 text-white"></i>
                  </button>
                </div>
              </div>
            </section>
          )}
          <section className="row  d-flex justify-content-center mt-50 mb-50">
            <div className="container text-center">
              <button
                onClick={handleShow}
                className="button -md -type-2 -accent-1 bg-accent-2 text-center mx-auto"
              >
                {language === "ru"
                  ? "ЗАПРОСИТЬ ЦЕНУ"
                  : language === "en"
                  ? "REQUEST A QUOTATION"
                  : "ANGEBOT ANFORDERN"}

                <FontAwesomeIcon icon={faArrowCircleRight} className="ms-2" />
              </button>
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {language === "ru"
                    ? "ЗАПРОСИТЬ ЦЕНУ"
                    : language === "en"
                    ? "REQUEST A QUOTATION"
                    : "ANGEBOT ANFORDERN"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-3 d-flex justify-content-between"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder={
                        language === "ru"
                          ? "Имя"
                          : language === "en"
                          ? "First Name"
                          : "Vorname"
                      }
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="border-1 me-2"
                      autoFocus
                      required
                    />
                    <Form.Control
                      type="text"
                      placeholder={
                        language === "ru"
                          ? "фамилия"
                          : language === "en"
                          ? "Last Name"
                          : "Familienname, Nachname"
                      }
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="border-1 ms-2"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 d-flex justify-content-between"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="email"
                      name="emailAddress"
                      value={formData.emailAddress}
                      onChange={handleChange}
                      className="border-1 me-2"
                      placeholder={
                        language === "ru"
                          ? "Электронная почта"
                          : language === "en"
                          ? "Email"
                          : "Email"
                      }
                      required
                    />
                    <Form.Control
                      type="text"
                      name="telephoneNumber"
                      value={formData.telephoneNumber}
                      onChange={handleChange}
                      className="border-1 ms-2"
                      placeholder={
                        language === "ru"
                          ? "Номер телефона"
                          : language === "en"
                          ? "Phone Number"
                          : "Telefonnummer"
                      }
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 d-flex justify-content-between"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Event Start Date"
                        value={formData.eventStartDate}
                        onChange={(newValue) =>
                          setFormData({ ...formData, eventStartDate: newValue })
                        }
                        minDate={dayjs()}
                        minTime={dayjs()}
                        className="col-6 pe-2"
                        required
                      />
                      <DateTimePicker
                        label="Event End Date"
                        value={formData.eventEndDate}
                        onChange={(newValue) =>
                          setFormData({ ...formData, eventEndDate: newValue })
                        }
                        minDate={formData.eventStartDate}
                        minTime={formData.eventStartDate}
                        className="col-6 ps-2"
                        required
                      />
                    </LocalizationProvider>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder={
                        language === "ru"
                          ? "Сообщение..."
                          : language === "en"
                          ? "Message..."
                          : "Nachricht..."
                      }
                      rows={4}
                      name="specialRequest"
                      value={formData.specialRequest}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                      {language === "ru"
                        ? "Закрывать"
                        : language === "en"
                        ? "Close"
                        : "Schließen"}
                    </Button>
                    <Button variant="primary" type="submit">
                      {language === "ru"
                        ? "Представлять на рассмотрение"
                        : language === "en"
                        ? "Submit"
                        : "Einreichen"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
            <div>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseMsg}
              >
                <Alert
                  onClose={handleCloseMsg}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {language === "ru"
                    ? "Ваши данные успешно отправлены, спасибо!"
                    : language === "en"
                    ? "Your Data Sent Successfully, Thank You!"
                    : "Ihre Daten wurden erfolgreich gesendet, vielen Dank!"}
                </Alert>
              </Snackbar>
            </div>
          </section>
          {meetingDetails.otherMeetingEvents?.length > 0 && (
            <section className=" layout-pb-lg">
              <div className="container">
                <div className="row y-gap-30 justify-between items-end">
                  <div className="col-auto">
                    <div className="text-15 uppercase mb-30 sm:mb-10">
                      {language === "ru"
                        ? "Изучить"
                        : language === "en"
                        ? "EXPLORE"
                        : "ERKUNDEN"}
                    </div>
                    <h2 className="text-64 md:text-40 lh-065">
                      {language === "ru"
                        ? "Другие встречи и события"
                        : language === "en"
                        ? "Other Meetings & Events"
                        : "Andere Meetings und Veranstaltungen"}
                    </h2>
                  </div>

                  <div className="col-auto">
                    <button
                      className="button -type-1"
                      onClick={() => navigate(`/${hotelUrl}/meetingandevents`)}
                    >
                      <i className="-icon icon-arrow-circle-right text-30"></i>
                      {language === "ru"
                        ? "Посмотреть все"
                        : language === "en"
                        ? "View All"
                        : "ALLE ANSEHEN"}
                    </button>
                  </div>
                </div>

                <div className="relative mt-100 sm:mt-50">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    breakpoints={{
                      300: { slidesPerView: 1 },
                      640: { slidesPerView: 1 },
                      768: { slidesPerView: 2 },
                      1024: { slidesPerView: 3 },
                    }}
                    className="overflow-hidden"
                    modules={[Navigation]}
                    onSwiper={setSwiperRef2}
                  >
                    {/* Example of adding multiple slides */}
                    {meetingDetails.otherMeetingEvents?.map((meeting) => (
                      <SwiperSlide key={meeting.facilityUrl}>
                        <Link
                          to={`${
                            meeting.isMoreDetails
                              ? `/${meeting.hotelUrl}/meetingandevents/${meeting.facilityUrl}`
                              : `/${meeting.hotelUrl}/meetingandevents#${meeting.facilityUrl}`
                          }`}
                          className="roomCard -type-2 -hover-button-center"
                        >
                          <div className="roomCard__image -no-rounded ratio ratio-45:54 -hover-button-center__wrap">
                            <img
                              src={meeting.facilityPhoto}
                              alt={meeting.facilityName}
                              className="img-ratio"
                            />

                            <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                              <span className="text-15 fw-500 text-white">
                                {language === "ru"
                                  ? "Посмотреть"
                                  : language === "en"
                                  ? "View"
                                  : "ANSEHEN"}
                              </span>
                            </div>
                          </div>
                          <div className="roomCard__content mt-30">
                            <div className="d-flex justify-between items-end">
                              <h3 className="roomCard__title lh-065 text-40 md:text-24">
                                {meeting.facilityName}
                              </h3>
                            </div>
                            <div className="d-flex  flex-wrap x-gap-20 pt-20  ">
                              <div className="d-flex items-center text-accent-1 ">
                                {language === "ru"
                                  ? "Размер:"
                                  : language === "en"
                                  ? "Size:"
                                  : "Größe:"}{" "}
                                {meeting.meetingSize}
                              </div>
                              |
                              <div className="d-flex items-center text-accent-1 ">
                                {language === "ru"
                                  ? "Ширина:"
                                  : language === "en"
                                  ? "Widths:"
                                  : "Breiten:"}{" "}
                                {meeting.meetingWidths}
                              </div>
                              |
                              <div className="d-flex items-center text-accent-1 ">
                                {language === "ru"
                                  ? "Длина:"
                                  : language === "en"
                                  ? "Length:"
                                  : "Länge:"}{" "}
                                {meeting.meetingLength}
                              </div>
                              |
                              <div className="d-flex items-center text-accent-1 ">
                                {language === "ru"
                                  ? "Высота потолка:"
                                  : language === "en"
                                  ? "Celling Height:"
                                  : "Deckenhöhe:"}{" "}
                                {meeting.meetingCellingHeight}
                              </div>
                              |
                              <div className="d-flex items-center text-accent-1 ">
                                {language === "ru"
                                  ? "Вместительность:"
                                  : language === "en"
                                  ? "Capacity:"
                                  : "Kapazität:"}{" "}
                                {meeting.meetingCapacity}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div
                    className="navAbsolute -type-2"
                    style={{ left: "-40px", right: "-40px" }}
                  >
                    <button
                      className="button -outline-accent-1 size-80 md:size-60 flex-center rounded-full"
                      onClick={() => swiperRef2.current?.slidePrev()}
                    >
                      <i className="icon-arrow-left text-24"></i>
                    </button>
                    <button
                      className="button -outline-accent-1 size-80 md:size-60 flex-center rounded-full"
                      onClick={() => swiperRef2.current?.slideNext()}
                    >
                      <i className="icon-arrow-right text-24"></i>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      ) : (
        navigate(-1)
      )}
    </>
  );
};

export default MeetingAndEventsDetails;
