import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqGroup } from "../../data/faqGroup/faqGroupSlice";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
SwiperCore.use([Pagination, Navigation]);
const OffersGroup = () => {
  const [openPanels, setOpenPanels] = useState([]);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const togglePanel = (index) => {
    if (openPanels.includes(index)) {
      // If panel is open, remove it from the array
      setOpenPanels(openPanels.filter((i) => i !== index));
    } else {
      // Otherwise add it to the array
      setOpenPanels([...openPanels, index]);
    }
  };
  return (
    <>
      <section className="hero -type-10 banner-mobile">
        <div
          className="hero__slider js-section-slider"
          data-gap="0"
          data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
          data-nav-prev="js-sliderHero-prev"
          data-nav-next="js-sliderHero-next"
          data-pagination="js-sliderHero-pagination"
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="hero__slide">
                <div className="hero__bg">
                  <div className="img-ratio">
                    {" "}
                    <img
                      src="https://cms.hwaidakhotels.com/images/uploads/banner-check-in.jpg"
                      alt=""
                      className="img-ratio"
                    />
                  </div>
                </div>

                <div className="hero__content text-center">
                  <div>
                    <Fade direction="up" delay="100">
                      <h1 className="hero__title capitalize text-white">
                        Offers
                      </h1>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div role="presentation" className="breadcrumbs-wrapper py-3 bg-light-1">
        <div className="container-fluid">
          <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
            <Link
              className="d-flex align-items-center"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to="/"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: "18px" }} fontSize="inherit" />
              Home
            </Link>
            {/*  <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              href="/material-ui/getting-started/installation/"
            >
              Sub
            </Link>*/}
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Offers
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <section className="layout-pt-lg  ">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-xl-10 col-lg-10">
              <div className="text-15 uppercase mb-20">Our Offers</div>
              <h2 className="text-50 lg:text-40 md:text-30">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                ex, rem facere laborum sint voluptate harum repellat ab sequi,
                nam voluptatem. Alias enim, deserunt quod repudiandae quia culpa
                modi saepe!
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-lg-5  layout-pb-lg">
        <div className="container-fluid">
          <div className="row x-gap-30 y-gap-60 pt-100 sm:pt-50">
            <div className="col-lg-4 col-md-6">
              <Link
                to={`/aladdin-beach-resort/offers`}
                onClick={() =>
                  localStorage.setItem("hotelUrl", "aladdin-beach-resort")
                }
                className="roomCard -type-2 -hover-button-center d-block bg-accent-1 rounded-16 overflow-hidden"
              >
                <div className="roomCard__image -no-line ratio ratio-45:43 -hover-button-center__wrap">
                  <img
                    src="img/cards/rooms/2/1.png"
                    alt=""
                    className="img-ratio"
                  />
                  <div className="roomCard__price text-15 fw-500 bg-white text-accent-1 rounded-0 w-auto h-auto">
                    From $499 / NIGHT{" "}
                  </div>
                  <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                    <span className="text-15 fw-500 text-white">View</span>
                  </div>
                </div>

                <div className="roomCard__content text-center px-30 py-30">
                  <h3 className="roomCard__title   text-40 md:text-24 text-white">
                    Aladdin Beach Resort Offers
                  </h3>

                  <p className="text-white mt-30">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem, consequuntur voluptate.
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link
                to={`/ali-baba-palace-hotel/offers`}
                onClick={() =>
                  localStorage.setItem("hotelUrl", "ali-baba-palace-hotel")
                }
                className="roomCard -type-2 -hover-button-center d-block bg-accent-1 rounded-16 overflow-hidden"
              >
                <div className="roomCard__image -no-line ratio ratio-45:43 -hover-button-center__wrap">
                  <img
                    src="img/cards/rooms/2/2.png"
                    alt=""
                    className="img-ratio"
                  />
                  <div className="roomCard__price text-15 fw-500 bg-white text-accent-1 rounded-0 w-auto h-auto">
                    From $499 / NIGHT{" "}
                  </div>
                  <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                    <span className="text-15 fw-500 text-white">View</span>
                  </div>
                </div>

                <div className="roomCard__content text-center px-30 py-30">
                  <h3 className="roomCard__title   text-40 md:text-24 text-white">
                    Ali Baba Palace Offers
                  </h3>

                  <p className="text-white mt-30">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem, consequuntur voluptate.
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link
                onClick={() =>
                  localStorage.setItem("hotelUrl", "jasmine-village")
                }
                to={`/jasmine-village/offers`}
                className="roomCard -type-2 -hover-button-center d-block bg-accent-1 rounded-16 overflow-hidden"
              >
                <div className="roomCard__image -no-line ratio ratio-45:43 -hover-button-center__wrap">
                  <img
                    src="img/cards/rooms/2/3.png"
                    alt=""
                    className="img-ratio"
                  />
                  <div className="roomCard__price text-15 fw-500 bg-white text-accent-1 rounded-0 w-auto h-auto">
                    From $499 / NIGHT{" "}
                  </div>
                  <div className="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                    <span className="text-15 fw-500 text-white">View</span>
                  </div>
                </div>

                <div className="roomCard__content text-center px-30 py-30">
                  <h3 className="roomCard__title  text-40 md:text-24 text-white">
                    Jasmine Village <br /> Offers
                  </h3>

                  <p className="text-white mt-30">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem, consequuntur voluptate.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OffersGroup;
