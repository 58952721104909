import React, { useEffect, useRef } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const OfferDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const { roomUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const setSwiperRef2 = (swiper) => {
    swiperRef2.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  return (
    <>
      <section className="hero -type-10 banner-mobile">
        <div
          className="hero__slider js-section-slider"
          data-gap="0"
          data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
          data-nav-prev="js-sliderHero-prev"
          data-nav-next="js-sliderHero-next"
          data-pagination="js-sliderHero-pagination"
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="hero__slide">
                <div className="hero__bg">
                  <div className="img-ratio">
                    {" "}
                    <img
                      src="img/pageHero/1.png"
                      alt="room banner"
                      className="img-ratio"
                    />
                  </div>
                </div>

                <div className="hero__content text-center">
                  <div data-split="lines" data-anim-child="split-lines delay-3">
                    <Fade direction="up" delay="100">
                      <h1 className="hero__title capitalize text-white">
                        Honeymoon Offer
                      </h1>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div role="presentation" className="breadcrumbs-wrapper py-3 bg-light-1">
        <div className="container-fluid">
          <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
            <Link
              className="d-flex align-items-center"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to={`/`}
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: "18px" }} fontSize="inherit" />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              to={`/${hotelUrl}`}
            >
              {hotelUrl === "aladdin-beach-resort"
                ? "Aladdin Beach Resort "
                : hotelUrl === "ali-baba-palace-hotel" ||
                  hotelUrl === "ali-baba-palace"
                ? "Ali Baba Palace"
                : "Jasmine Village"}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              to={`/${hotelUrl}/offers`}
            >
              Offers
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              HONEYMOON OFFER
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <section className="layout-pt-md">
        <div className="container">
          <div className="row y-gap-40 x-gap-60 justify-between">
            <div className="col-xl-8 col-lg-8">
              {/* <div className="fw-500 mb-30">$173 PER NIGHT </div> */}
              <h2 className="text-40">HONEYMOON OFFER</h2>

              <div className="line -horizontal bg-border mt-50 mb-50"></div>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur, similique, dolorum dolore eaque laboriosam libero sit
                atque fugiat consectetur consequuntur quas excepturi omnis
                nostrum velit asperiores dolor veritatis nulla mollitia?
              </p>
              <h2 className="text-40 mt-50 mb-20">Terms & Conditions</h2>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faArrowCircleRight} className="me-2" />{" "}
                  Offer is valid until June 30th, 2024, excluding public
                  holidays and peak periods.
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon icon={faArrowCircleRight} className="me-2" />
                  Offer based on hotel availability.
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon icon={faArrowCircleRight} className="me-2" />
                  Offer cannot be combined with any other offer or any special
                  promotion.{" "}
                </li>
              </ul>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div className="sidebar -rooms-single px-30 py-30   border-1 shadow-1">
                <h2 className="text-40">Other Offers </h2>
                <div className="row x-gap-50 y-gap-20 justify-between pt-20 ">
                  <div className="row  y-gap-30 justify-between align-items-center">
                    <ul>
                      <li>
                        <Link to="#.">Offer 01</Link>
                      </li>
                      <li>
                        <Link to="#.">Offer 02</Link>
                      </li>
                      <li>
                        <Link to="#.">Offer 03</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line -horizontal bg-border mt-50 mb-50"></div>
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-lg bg-light-1">
        <div className="container">
          <div className="row justify-center text-center mb-100 sm:mb-50">
            <div className="col-auto">
              <div className="text-15 uppercase mb-30 sm:mb-10">EXPLORE</div>
              <h2 className="text-64 md:text-40">Gallery</h2>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-10 col-md-11">
                <Swiper
                  className="overflow-visible"
                  modules={[Navigation]}
                  spaceBetween={180}
                  slidesPerView={1}
                  onSwiper={setSwiperRef}
                >
                  <SwiperSlide>
                    <div className="ratio ratio-16:9">
                      <img
                        src="img/pageHero/1.png"
                        alt=""
                        className="img-ratio"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="ratio ratio-16:9">
                      <img
                        src="img/pageHero/1.png"
                        alt=""
                        className="img-ratio"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          <div className="navAbsolute -type-3">
            <button
              className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-prev"
              onClick={() => swiperRef.current?.slidePrev()} // Call handlePrev when prev button is clicked
            >
              <i className="icon-arrow-left text-24 text-white"></i>
            </button>
            <button
              className="size-80 flex-center bg-accent-1-50 blur-1 rounded-full js-slider1-next"
              onClick={() => swiperRef.current?.slideNext()} // Call handleNext when next button is clicked
            >
              <i className="icon-arrow-right text-24 text-white"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
export default OfferDetails;
