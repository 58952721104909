import React, { Fragment, useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendarAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import { fetchOnlineCheckIn } from "../../data/online-check-in/onlineCheckInSlice";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";

const OnlineCheckIn = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { onlineCheckIn, loading, error } = useSelector(
    (state) => state.onlineCheckIn
  );
  const [loadingForm, setLoadingForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOperation, setErrorOperation] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOnlineCheckIn(language));
  }, [dispatch, language]);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const [checkInDate, setCheckInDate] = useState(today);
  const [checkOutDate, setCheckOutDate] = useState(tomorrow);
  const [birthDate, setBirthDate] = useState(new Date());
  // const [passportScan, setPassportScan] = useState(null);
  // const [spousePassportScan, setSpousePassportScan] = useState(null);
  // const [marriageCertificate, setMarriageCertificate] = useState(null);
  // const [depositReceipt, setDepositReceipt] = useState(null);
  const [terms, setTerms] = useState(false);

  const specialRequestRef = useRef();

  // const [entries, setEntries] = useState([]);

  const [showTextarea1, setShowTextarea1] = useState(false);
  const [showTextarea2, setShowTextarea2] = useState(false);

  const handleRadioChange1 = (event) => {
    setShowTextarea1(event.target.value === "yes");
  };
  const handleRadioChange2 = (event) => {
    setShowTextarea2(event.target.value === "yes2");
  };

  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
  };

  const [hotelName, setHotelName] = useState("");
  const [guestName, setGuestName] = useState("");
  const [reservationThrough, setReservationThrough] = useState("");
  const [rooms, setRooms] = useState(0);
  const [channelName, setChannelName] = useState("");

  const [arrivalFlight, setArrivalFlight] = useState("");
  const [departureFlight, setDepartureFlight] = useState("");

  const [chronicDiseases, setChronicDiseases] = useState(false);
  const [chronicDiseasesDescription, setChronicDiseasesDescription] =
    useState("");
  const [last14Days, setLast14Days] = useState(false);
  const [last14DaysDescription, setLast14DaysDescription] = useState("");

  const nationalityRef = useRef();
  const passportRef = useRef();
  const mobileNumberRef = useRef();
  const emailRef = useRef();
  const chronicDiseasesDescriptionRef = useRef();
  const last14DaysDescriptionRef = useRef();
  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    setCheckOutDate(nextDay);
  };
  const [entries, setEntries] = useState([
    { name: "", birthDate: "", passportNumber: "", file: "" },
  ]);
  const handleInputChange = (index, field, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index] = {
      ...updatedEntries[index],
      [field]: value,
    };
    setEntries(updatedEntries);
  };

  const addEntry = () => {
    setEntries([
      ...entries,
      { name: "", birthDate: "", passportNumber: "", file: null },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const formData = new FormData();

    // Append main guest information
    formData.append("HotelName", hotelName);
    formData.append("GuestName", guestName);
    formData.append("ReservationThrough", reservationThrough);
    formData.append("ChannelName", channelName);
    formData.append(
      "CheckInDate",
      checkInDate ? checkInDate.toISOString().split("T")[0] : ""
    );
    formData.append(
      "CheckoutDate",
      checkOutDate ? checkOutDate.toISOString().split("T")[0] : ""
    );
    formData.append("ArrivalFlight", arrivalFlight);
    formData.append("DepartureFlight", departureFlight);
    formData.append("NumberofRooms", rooms);
    formData.append("Nationality", nationalityRef.current.value);
    formData.append(
      "GuestBirthDate",
      birthDate ? birthDate.toISOString().split("T")[0] : ""
    );
    formData.append("Passport", passportRef.current.value);
    formData.append("MobileNumber", mobileNumberRef.current.value);
    formData.append("EmailAddress", emailRef.current.value);
    formData.append("chronicdiseases", chronicDiseases.toString());
    formData.append(
      "chronicdiseasesdescription",
      chronicDiseases ? chronicDiseasesDescriptionRef.current.value : ""
    );
    formData.append("last14days", last14Days.toString());
    formData.append(
      "last14daysdescription",
      last14Days ? last14DaysDescriptionRef.current.value : ""
    );
    formData.append("SpecialRequest", specialRequestRef.current.value);

    // Append files for the main guest
    const passportFileInput = document.getElementById("passportFile");
    const spouseFileInput = document.getElementById("spouseFile");
    const marriageCertificateFileInput = document.getElementById(
      "marriageCertificateFile"
    );
    const depositReceiptFileInput =
      document.getElementById("depositReceiptFile");

    if (passportFileInput && passportFileInput.files[0]) {
      formData.append("ScanFile", passportFileInput.files[0]);
    }
    if (spouseFileInput && spouseFileInput.files[0]) {
      formData.append("ScanFileWife", spouseFileInput.files[0]);
    }
    if (marriageCertificateFileInput && marriageCertificateFileInput.files[0]) {
      formData.append(
        "MarriageCertificate",
        marriageCertificateFileInput.files[0]
      );
    }
    if (depositReceiptFileInput && depositReceiptFileInput.files[0]) {
      formData.append("DepositReceipt", depositReceiptFileInput.files[0]);
    }

    // Append companions information
    entries.forEach((entry, index) => {
      formData.append(`CompanionsGuests[${index}].GuestName`, entry.name);
      formData.append(
        `CompanionsGuests[${index}].GuestBirthDate`,
        entry.birthDate
      );
      formData.append(
        `CompanionsGuests[${index}].GuestPassport`,
        entry.passportNumber
      );
      if (entry.file) {
        formData.append(
          `CompanionsGuests[${index}].GuestUploadFile`,
          entry.file
        );
      }
    });

    try {
      await axios.post(
        "https://api.hwaidakhotels.com/api/OnlineCheckIn",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSuccessOperation(true);
      setLoadingForm(false);
    } catch (error) {
      setErrorOperation(true);
      setSuccessOperation(false);
      setLoadingForm(false);
      setErrorMessage(error.message);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOperation(false);
    setErrorOperation(false);
  };
  const fileInputRefx = useRef(null);
  const handleFileChangex = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const button = document.getElementById("fileButtonx");
      if (button) {
        button.innerText = fileName; // Update button text with file name
      }
      handleInputChange(0, "file", file); // Handle file input change
    }
  };
  const triggerFileInputx = () => {
    fileInputRefx.current.click(); // Trigger file input click event
  };
  const fileInputRefs = {
    passport: useRef(null),
    spouse: useRef(null),
    marriageCertificate: useRef(null),
    depositReceipt: useRef(null),
  };

  const handleFileChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const button = document.getElementById(`${id}Button`);
      if (button) {
        button.innerText = fileName; // Update button text with file name
      }
      // Handle file input change as needed for each specific file
    }
  };

  const triggerFileInput = (id) => {
    fileInputRefs[id].current.click(); // Trigger file input click event
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{onlineCheckIn.pageDetails?.pageMetatagTitle}</title>
            <meta
              name="description"
              content={onlineCheckIn.pageDetails?.pageMetatagDescription}
            />
          </Helmet>

          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? onlineCheckIn.pageDetails?.pageBannerMobile
                              : isTablet
                              ? onlineCheckIn.pageDetails?.pageBannerTablet
                              : onlineCheckIn.pageDetails?.pageBannerPC
                          }
                          alt={onlineCheckIn.pageDetails?.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {onlineCheckIn.pageDetails?.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to="/"
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                {/*  <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              href="/material-ui/getting-started/installation/"
            >
              Sub
            </Link>*/}
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {onlineCheckIn.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-10 col-lg-10">
                  <div className="mb-30">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_632_5288)">
                        <path
                          d="M47.9511 0.57683C45.9935 -0.55357 43.4816 0.119689 42.3516 2.07726L36.7643 11.7548H24.7149C24.1755 11.7548 23.7383 12.1921 23.7383 12.7313C23.7383 13.2706 24.1755 13.7079 24.7149 13.7079H35.6366L31.5821 20.7308H8.78948C8.25011 20.7308 7.81289 21.1681 7.81289 21.7073C7.81289 22.2467 8.25011 22.6839 8.78948 22.6839H30.4543L28.199 26.5903H8.78948C8.25011 26.5903 7.81289 27.0276 7.81289 27.5669C7.81289 28.1061 8.25011 28.5435 8.78948 28.5435H27.795L27.3742 32.4498H8.78948C8.25011 32.4498 7.81289 32.8871 7.81289 33.4264C7.81289 33.9657 8.25011 34.403 8.78948 34.403H27.1638L27.1118 34.8853C27.0704 35.2697 27.2597 35.6424 27.5944 35.8356C27.7461 35.9232 27.9146 35.9665 28.0826 35.9665C28.2857 35.9665 28.488 35.9033 28.6588 35.7785L34.8944 31.2232C35.0043 31.1429 35.0961 31.0407 35.1641 30.9229L42.7745 17.7414V36.6703C42.7745 38.8585 40.9943 40.6388 38.8061 40.6388H16.1736C15.8246 40.6388 15.5022 40.8249 15.3278 41.1271L11.913 47.0418L8.49817 41.1271C8.32375 40.8249 8.00138 40.6388 7.65244 40.6388H5.92164C3.7334 40.6388 1.95317 38.8585 1.95317 36.6703V17.6764C1.95317 15.4882 3.7334 13.7079 5.92164 13.7079H15.9257C16.4651 13.7079 16.9023 13.2706 16.9023 12.7313C16.9023 12.192 16.4651 11.7548 15.9257 11.7548H5.92164C2.65642 11.7548 0 14.4112 0 17.6764V36.6703C0 39.9355 2.65642 42.592 5.92164 42.592H7.08856L11.0673 49.4832C11.2417 49.7854 11.5641 49.9715 11.913 49.9715C12.2619 49.9715 12.5843 49.7854 12.7587 49.4832L16.7374 42.592H38.8062C42.0714 42.592 44.7278 39.9355 44.7278 36.6703V17.6764C44.7278 16.8393 44.5503 16.0123 44.2107 15.2541L49.4516 6.17648C50.5818 4.21901 49.9087 1.70703 47.9511 0.57683ZM46.9745 2.26828C47.9994 2.85999 48.3517 4.17507 47.76 5.1999L47.202 6.16643L43.485 4.02037L44.043 3.05385C44.6347 2.02911 45.9497 1.67686 46.9745 2.26828ZM29.7203 28.9075L32.5315 30.5306L29.2904 32.8984L29.7203 28.9075ZM33.9609 29.1006L30.2439 26.9545L42.5084 5.71182L46.2254 7.85787L33.9609 29.1006Z"
                          fill="#122223"
                        ></path>
                        <path
                          d="M20.3203 13.708C20.5771 13.708 20.8291 13.6035 21.0117 13.4219C21.1934 13.2402 21.2979 12.9883 21.2979 12.7314C21.2979 12.4746 21.1934 12.2227 21.0117 12.041C20.8291 11.8595 20.5781 11.7549 20.3203 11.7549C20.0635 11.7549 19.8115 11.8594 19.6299 12.041C19.4482 12.2227 19.3447 12.4746 19.3447 12.7314C19.3447 12.9883 19.4481 13.2402 19.6299 13.4219C19.8125 13.6035 20.0635 13.708 20.3203 13.708Z"
                          fill="#122223"
                        ></path>
                      </g>
                      <defs>
                        <clippath id="clip0_632_5288">
                          <rect width="50" height="50" fill="white"></rect>
                        </clippath>
                      </defs>
                    </svg>
                  </div>

                  <h2 className="text-64 md:text-40 capitalize">
                    {onlineCheckIn.pageDetails?.pageTitle}
                  </h2>
                  <p className="lh-17 mt-30">
                    {onlineCheckIn.pageDetails?.pageMetatagDescription}
                  </p>

                  <form
                    className="contactForm row y-gap-30 pt-60"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Наименование отеля*"
                          : language === "en"
                          ? "Hotel Name*"
                          : "Hotelname"}
                      </p>

                      <Form.Select
                        required
                        value={hotelName}
                        onChange={(e) => setHotelName(e.target.value)}
                      >
                        <option value="" hidden>
                          {language === "ru"
                            ? "Выбрать"
                            : language === "en"
                            ? "Select"
                            : "Wählen"}
                        </option>
                        {onlineCheckIn.checkInHotels?.map((hotel, index) => (
                          <option value={hotel.hotelName} key={index}>
                            {hotel.hotelName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? language === "en"
                            ? "Имя гостей"
                            : "Guest Name*"
                          : "Name des Gastes"}
                      </p>
                      <div className="form-input">
                        <Form.Control
                          type="text"
                          required
                          value={guestName}
                          onChange={(e) => setGuestName(e.target.value)}
                          placeholder={
                            language === "ru"
                              ? "Введите Ваше имя"
                              : language === "en"
                              ? "Enter You'r Name"
                              : "gib deinen Namen ein"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Забронировано через"
                          : language === "en"
                          ? "Reservation Through*"
                          : "Reservierung über*"}
                      </p>
                      <div className="form-input">
                        <Form.Select
                          required
                          value={reservationThrough}
                          onChange={(e) =>
                            setReservationThrough(e.target.value)
                          }
                        >
                          <option value="" hidden>
                            {language === "ru"
                              ? "Выбрать"
                              : language === "en"
                              ? "Select"
                              : "Wählen"}
                          </option>
                          {onlineCheckIn.checkInReservationThroughs?.map(
                            (reservation, index) => (
                              <option
                                value={reservation.reservationThroughId}
                                key={reservation.reservationThroughId}
                              >
                                {reservation.reservationThroughEn}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Ресурс/ Тур. агент"
                          : language === "en"
                          ? "Channel/Travel Agent*"
                          : "Kanal/Reisebüro*"}
                      </p>
                      <div className="form-input">
                        <Form.Control
                          type="text"
                          required
                          value={channelName}
                          onChange={(e) => setChannelName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Дата заезда"
                          : language === "en"
                          ? "Check In Date:"
                          : "Check-in Datum:"}
                      </p>
                      <div className="form-input">
                        <DatePicker
                          selected={checkInDate}
                          onChange={handleCheckInDateChange}
                          dateFormat="MMMM d, yyyy"
                          minDate={today}
                          placeholderText={
                            language === "ru"
                              ? "Выберите дату заезда"
                              : language === "en"
                              ? "Select a check-in date"
                              : "Wähle ein Check-in-Datum"
                          }
                          className="form-control col-12"
                        />
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          style={{
                            position: "absolute",
                            right: "5%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#777",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Дата выезда"
                          : language === "en"
                          ? "Check Out Date:"
                          : "Überprüfe das Datum:"}
                      </p>
                      <div className="form-input">
                        <DatePicker
                          selected={checkOutDate}
                          onChange={(date) => setCheckOutDate(date)}
                          dateFormat="MMMM d, yyyy"
                          minDate={
                            checkInDate
                              ? new Date(
                                  checkInDate.getTime() + 24 * 60 * 60 * 1000
                                )
                              : tomorrow
                          }
                          placeholderText={
                            language === "ru"
                              ? "Выберите дату выезда"
                              : language === "en"
                              ? "Select a check-out date"
                              : "Überprüfe das Datum"
                          }
                        />
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          style={{
                            position: "absolute",
                            right: "5%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#777",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Прилет"
                          : language === "en"
                          ? "Arrival Flight*"
                          : "Ankunftsflug*"}
                      </p>
                      <div className="form-input">
                        <textarea
                          className="border-1 pt-2"
                          rows="3"
                          value={arrivalFlight}
                          onChange={(e) => setArrivalFlight(e.target.value)}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Вылет*"
                          : language === "en"
                          ? "Departure Flight*"
                          : "Abgehender Flug*"}
                      </p>
                      <div className="form-input">
                        <textarea
                          className="border-1 pt-2"
                          rows="3"
                          value={departureFlight}
                          onChange={(e) => setDepartureFlight(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <h3>
                      {language === "ru"
                        ? "Персональные данные"
                        : language === "en"
                        ? "Personal Details"
                        : "Persönliche Daten"}
                    </h3>
                    <hr className="py-0" />
                    <div className="col-lg-6">
                      <div className="form-input ">
                        <p className="mb-2 text-left">
                          {language === "ru"
                            ? "Национальность"
                            : language === "en"
                            ? "Nationality*"
                            : "Staatsangehörigkeit*"}
                        </p>
                        <Form.Select required ref={nationalityRef}>
                          <option value="" hidden>
                            {language === "ru"
                              ? "Выбрать"
                              : language === "en"
                              ? "Select"
                              : "Wählen"}
                          </option>
                          {onlineCheckIn.nationalities?.map((nationality) => (
                            <option
                              key={nationality.nationalityId}
                              value={nationality.nationalityId}
                            >
                              {nationality.nationalityName}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-lg-6   ">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Дата рождения"
                          : language === "en"
                          ? "Birth Date *"
                          : "Geburtsdatum *"}
                      </p>
                      <div className="form-input  ">
                        <DatePicker
                          selected={birthDate}
                          onChange={(date) => setBirthDate(date)}
                          dateFormat="MMMM d, yyyy"
                        />
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          style={{
                            position: "absolute",
                            right: "5%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#777",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Номер паспорта/ Египетское ID"
                          : language === "en"
                          ? "Passport/Egyptian ID number*"
                          : "Reisepass-/ägyptische ID-Nummer*"}
                      </p>
                      <div className="form-input">
                        <Form.Control type="text" required ref={passportRef} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Номер мобильного телефона"
                          : language === "en"
                          ? "Mobile Number*"
                          : "Handynummer*"}
                      </p>
                      <div className="form-input">
                        <Form.Control
                          type="text"
                          required
                          ref={mobileNumberRef}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p className="mb-2 text-left">
                        {language === "ru"
                          ? "Адрес электронной почты"
                          : language === "en"
                          ? "Email*"
                          : "Email*"}
                      </p>
                      <div className="form-input">
                        <Form.Control
                          type="email"
                          required
                          placeholder={
                            language === "ru"
                              ? "Адрес электронной почты"
                              : language === "en"
                              ? "Email*"
                              : "Email*"
                          }
                          ref={emailRef}
                        />
                      </div>
                    </div>
                    <h3>
                      {language === "ru"
                        ? "Детали сопровождающего"
                        : language === "en"
                        ? "Companion's Details"
                        : "Details zum Begleiter"}
                    </h3>
                    <hr className="py-0" />
                    <div>
                      <div className="col-12 col-lg-12 mb-2">
                        <p className="mb-2 text-left">
                          {language === "ru"
                            ? "Количество номеров"
                            : language === "en"
                            ? "Number of Rooms*"
                            : "Anzahl der Räume*"}
                        </p>
                        <div className="form-input">
                          <Form.Select
                            required
                            value={rooms}
                            onChange={(e) => setRooms(e.target.value)}
                          >
                            <option value="" hidden>
                              {language === "ru"
                                ? "Выбрать"
                                : language === "en"
                                ? "Select"
                                : "Wählen"}
                            </option>
                            <option value="1">
                              1{" "}
                              {language === "ru"
                                ? "комнатa"
                                : language === "en"
                                ? "Room"
                                : "ZIMMER"}
                            </option>
                            <option value="2">
                              2{" "}
                              {language === "ru"
                                ? "комнаты"
                                : language === "en"
                                ? "Rooms"
                                : "ZIMMER"}
                            </option>
                            <option value="3">
                              3{" "}
                              {language === "ru"
                                ? "комнаты"
                                : language === "en"
                                ? "Rooms"
                                : "ZIMMER"}
                            </option>
                          </Form.Select>
                        </div>
                      </div>
                      {entries.map((entry, index) => (
                        <Fragment key={index}>
                          <h2 className="py-3">
                            {language === "ru"
                              ? language === "en"
                                ? "Гость №"
                                : "Guest"
                              : "Gast"}{" "}
                            {index + 1}
                          </h2>
                          <div className="d-flex justify-content-between flex-wrap x-gap-10">
                            <div className="col-12 col-lg-6 mb-2">
                              <Form.Control
                                type="text"
                                required
                                value={entry.name}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                                placeholder={
                                  language === "ru"
                                    ? "Имя и фамилия"
                                    : language === "en"
                                    ? "Name and surname"
                                    : "Name und Nachname"
                                }
                              />
                            </div>
                            <div className="col-12 col-lg-6 mb-2">
                              <Form.Control
                                type="text"
                                required
                                value={entry.birthDate}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "birthDate",
                                    e.target.value
                                  )
                                }
                                placeholder={
                                  language === "ru"
                                    ? "Дата рождения"
                                    : language === "en"
                                    ? "Birth date"
                                    : "Geburtsdatum"
                                }
                              />
                            </div>
                            <div className="col-12 col-lg-6 mb-2">
                              <Form.Control
                                type="text"
                                required
                                value={entry.passportNumber}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "passportNumber",
                                    e.target.value
                                  )
                                }
                                placeholder={
                                  language === "ru"
                                    ? "Номер паспорта/ Египетское ID"
                                    : language === "en"
                                    ? "Passport number/ID"
                                    : "Reisepassnummer/Personalausweis"
                                }
                              />
                            </div>
                            <div className="col-12 col-lg-6 mb-2">
                              {/* <Form.Control
                                type="file"
                                required
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "file",
                                    e.target.files[0]
                                  )
                                }
                                style={{
                                  lineHeight: "3.25",
                                  padding: "0 0 0 10px",
                                }}
                              /> */}
                              <input
                                ref={fileInputRefx}
                                type="file"
                                id="passportFile"
                                accept="*"
                                size="lg"
                                required
                                onChange={handleFileChangex}
                                style={{ display: "none" }}
                              />
                              <button
                                type="button"
                                id="fileButtonx"
                                className="form-control text-left"
                                onClick={triggerFileInputx}
                                style={{
                                  lineHeight: "3.25",
                                  padding: "0 0 0 15px",
                                  border: "1px solid #ced4da",
                                  borderRadius: "4px",
                                  background: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                {language === "ru"
                                  ? "Выберите файл"
                                  : language === "en"
                                  ? "Choose File"
                                  : "Datei wählen"}
                              </button>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                      <Button
                        variant="primary"
                        onClick={addEntry}
                        className="mt-2"
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className="pr-2" />{" "}
                        {language === "ru" ? "Добавить гостя" : "Add Guest"}
                      </Button>
                    </div>
                    <hr className="py-0" />
                    <h3>
                      {language === "ru" ? "Медицинские данные" : "Medical"}
                    </h3>
                    <div className="col-lg-12">
                      <div className="clarify1 text-left">
                        <Form.Label controlid="clarify1">
                          {language === "ru"
                            ? "Страдаете ли Вы хроническими заболеваниями?"
                            : language === "en"
                            ? "Do you suffer from any chronic diseases?"
                            : "Leiden Sie an chronischen Erkrankungen?"}
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Group className="d-flex align-items-center">
                            <input
                              type="radio"
                              id="yes"
                              label="yes"
                              name="clarify1"
                              value="yes"
                              className="w-auto p-0"
                              onChange={handleRadioChange1}
                              checked={chronicDiseases && true}
                              onClick={() => setChronicDiseases(true)}
                            />
                            <label htmlFor="yes" className="mb-0 ms-1">
                              {language === "ru"
                                ? "Да"
                                : language === "en"
                                ? "Yes"
                                : "Ja"}
                            </label>
                          </Form.Group>
                          <Form.Group className="d-flex align-items-center ms-3">
                            <input
                              type="radio"
                              id="no"
                              label="no"
                              name="clarify1"
                              value="no"
                              className="w-auto p-0"
                              onChange={handleRadioChange1}
                              checked={!chronicDiseases && true}
                              onClick={() => setChronicDiseases(false)}
                            />
                            <label htmlFor="no" className="mb-0 ms-1">
                              {language === "ru"
                                ? language === "en"
                                  ? "Нет"
                                  : " No"
                                : "NEIN"}
                            </label>
                          </Form.Group>
                        </div>
                        {showTextarea1 && (
                          <textarea
                            className="border-1 pt-2"
                            rows="3"
                            placeholder="Please clarify in details *"
                            required
                            ref={chronicDiseasesDescriptionRef}
                          ></textarea>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="clarify1 text-left">
                        <Form.Label controlid="clarify2">
                          {language === "ru"
                            ? `Замечали ли Вы какие-либо синдромы мединских недугов за последние 14 дней (температура, тошнота, диарея)?`
                            : `In last 14 days did you suffer from any Disease
                          symptoms (Fever, Vomiting, Diarrhea etc.)?`}
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Group className="d-flex align-items-center">
                            <input
                              type="radio"
                              id="yes2"
                              label="yes2"
                              name="clarify2"
                              value="yes2"
                              className="w-auto p-0"
                              onChange={handleRadioChange2}
                              checked={last14Days && true}
                              onClick={() => setLast14Days(true)}
                            />
                            <label htmlFor="yes2" className="mb-0 ms-1">
                              {language === "ru"
                                ? "Да"
                                : language === "en"
                                ? "Yes"
                                : "Ja"}
                            </label>
                          </Form.Group>
                          <Form.Group className="d-flex align-items-center ms-3">
                            <input
                              type="radio"
                              id="no2"
                              label="no2"
                              name="clarify2"
                              value="no2"
                              className="w-auto p-0"
                              onChange={handleRadioChange2}
                              checked={!last14Days && true}
                              onClick={() => setLast14Days(false)}
                            />
                            <label htmlFor="no2" className="mb-0 ms-1">
                              {language === "ru"
                                ? language === "en"
                                  ? "Нет"
                                  : " No"
                                : "NEIN"}
                            </label>
                          </Form.Group>
                        </div>
                        {showTextarea2 && (
                          <textarea
                            className="border-1 pt-2"
                            rows="3"
                            placeholder="Please clarify in details *"
                            required
                            ref={last14DaysDescriptionRef}
                          ></textarea>
                        )}
                      </div>
                    </div>
                    <h3>
                      {language === "ru"
                        ? "Документы"
                        : language === "en"
                        ? "Documents / Papers"
                        : "Dokumente / Unterlagen"}
                    </h3>
                    <hr className="py-0" />
                    <div className="col-lg-6 my-auto">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex justify-content-start mb-2">
                          {language === "ru"
                            ? "Копия или скан пасспорта/ ID"
                            : language === "en"
                            ? "Passport's/ID's Photo or Scan"
                            : "Pass-/Personalausweisfoto oder Scan"}
                        </Form.Label>
                        <input
                          ref={fileInputRefs.passport}
                          type="file"
                          id="passportFile"
                          accept="*"
                          size="lg"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileChange("passport", e)}
                        />
                        <button
                          type="button"
                          id="passportButton"
                          className="form-control text-left"
                          onClick={() => triggerFileInput("passport")}
                          style={{
                            lineHeight: "3.25",
                            padding: "0 0 0 15px",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                            background: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          {language === "ru"
                            ? "Выберите файл"
                            : language === "en"
                            ? "Choose File"
                            : "Datei wählen"}
                        </button>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 my-auto">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex justify-content-start mb-2">
                          {language === "ru"
                            ? "Паспорт/ ID супруга"
                            : language === "en"
                            ? "Spouse's Passport/ ID"
                            : "Reisepass/Personalausweis des Ehepartners"}
                        </Form.Label>
                        <input
                          ref={fileInputRefs.spouse}
                          type="file"
                          id="spouseFile"
                          accept="*"
                          size="lg"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileChange("spouse", e)}
                        />
                        <button
                          type="button"
                          id="spouseButton"
                          className="form-control text-left"
                          onClick={() => triggerFileInput("spouse")}
                          style={{
                            lineHeight: "3.25",
                            padding: "0 0 0 15px",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                            background: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          {language === "ru"
                            ? "Выберите файл"
                            : language === "en"
                            ? "Choose File"
                            : "Datei wählen"}
                        </button>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 my-auto">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex justify-content-start mb-2">
                          {language === "ru"
                            ? "Свидетельство о браке"
                            : "Marriage Certificate"}
                        </Form.Label>
                        <input
                          ref={fileInputRefs.marriageCertificate}
                          type="file"
                          id="marriageCertificateFile"
                          accept="*"
                          size="lg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleFileChange("marriageCertificate", e)
                          }
                        />
                        <button
                          type="button"
                          id="marriageCertificateButton"
                          className="form-control text-left"
                          onClick={() =>
                            triggerFileInput("marriageCertificate")
                          }
                          style={{
                            lineHeight: "3.25",
                            padding: "0 0 0 15px",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                            background: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          {language === "ru"
                            ? "Выберите файл"
                            : language === "en"
                            ? "Choose File"
                            : "Datei wählen"}
                        </button>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 my-auto">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex justify-content-start mb-2">
                          {language === "ru"
                            ? "Квитанция об уплате депозита"
                            : language === "en"
                            ? "Deposit Receipt"
                            : "Einzahlungsbeleg"}
                        </Form.Label>
                        <input
                          ref={fileInputRefs.depositReceipt}
                          type="file"
                          id="depositReceiptFile"
                          accept="*"
                          size="lg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleFileChange("depositReceipt", e)
                          }
                        />
                        <button
                          type="button"
                          id="depositReceiptButton"
                          className="form-control text-left"
                          onClick={() => triggerFileInput("depositReceipt")}
                          style={{
                            lineHeight: "3.25",
                            padding: "0 0 0 15px",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                            background: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          {language === "ru"
                            ? "Выберите файл"
                            : language === "en"
                            ? "Choose File"
                            : "Datei wählen"}
                        </button>
                      </Form.Group>
                    </div>

                    <h3>
                      {language === "ru"
                        ? "Специальные пожелания"
                        : language === "en"
                        ? "Special Request"
                        : "Besondere Bitte"}
                    </h3>
                    <hr className="py-0" />

                    <div className="col-12">
                      <div className="form-input ">
                        <textarea
                          className="border-1"
                          rows="8"
                          placeholder={
                            language === "ru"
                              ? "В зависимости от наличия"
                              : language === "en"
                              ? "According to the availability"
                              : "Je nach Verfügbarkeit"
                          }
                          ref={specialRequestRef}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12 py-0">
                      <Form.Group className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="terms"
                          label="terms"
                          name="terms"
                          checked={terms && true}
                          value={terms}
                          onChange={() => setTerms(!terms)}
                          className=" mr-5  "
                          style={{ width: "20px" }}
                          variant="secondary"
                        />
                        <label htmlFor="terms" className="mb-0 ms-1">
                          {language === "ru"
                            ? "Я согласен(а) со всеми условиями"
                            : language === "en"
                            ? "I agree to the Terms and Conditions"
                            : "Ich stimme den AGB zu"}
                        </label>
                      </Form.Group>
                    </div>
                    <div className="col-12">
                      <button
                        className={`button -md -type-2 w-1/1 bg-accent-2 ${
                          terms && "-accent-1"
                        } `}
                        type="submit"
                        disabled={!terms}
                      >
                        {loadingForm
                          ? language === "ru"
                            ? "Загрузка..."
                            : language === "en"
                            ? "Loading..."
                            : "Wird geladen..."
                          : language === "ru"
                          ? "ОФОРМИТЬ"
                          : language === "en"
                          ? "Submit"
                          : "Einreichen"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {errorOperation ? (
              <>
                <Snackbar
                  open={errorOperation}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </>
            ) : (
              <Snackbar
                open={successOperation}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {language === "ru"
                    ? "Ваша дата успешно отправлена. Спасибо!"
                    : language === "en"
                    ? "Your Date Sent Successfully. Thank You!"
                    : "Ihr Datum wurde erfolgreich gesendet. Danke!"}
                </Alert>
              </Snackbar>
            )}
          </section>
        </>
      )}
    </>
  );
};

export default OnlineCheckIn;
