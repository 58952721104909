import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./baseUrl";

// Define initial state
const initialState = {
  privacy: [],
  loading: true,
  error: null,
};

// Async thunk for fetching hotels data
export const fetchPrivacy = createAsyncThunk(
  "privacy/fetchPrivacy",
  async (language) => {
    const response = await axios.get(
      `${baseUrl}/api/GroupHome/privacyPolicy/${language}`
    );
    return response.data;
  }
);

// Slice configuration
const privacySlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrivacy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPrivacy.fulfilled, (state, action) => {
        state.loading = false;
        state.privacy = action.payload;
      })
      .addCase(fetchPrivacy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default privacySlice.reducer;
