import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Loader";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchFacilities } from "./../../data/facilities/facilitiesSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const Facilities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { facilities, loading, error } = useSelector(
    (state) => state.facilities
  );
  useEffect(() => {
    dispatch(fetchFacilities({ hotelUrl, language }));
  }, [dispatch, hotelUrl, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{facilities?.pageDetails.pageMetatagTitle}</title>
            <meta
              name="description"
              content={facilities?.pageDetails.pageMetatagDescription}
            />
          </Helmet>
          <section className="hero -type-10 banner-mobile">
            <div
              className="hero__slider js-section-slider"
              data-gap="0"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-nav-prev="js-sliderHero-prev"
              data-nav-next="js-sliderHero-next"
              data-pagination="js-sliderHero-pagination"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="hero__slide">
                    <div className="hero__bg">
                      <div className="img-ratio">
                        <img
                          src={
                            isMobile
                              ? facilities?.pageDetails.pageBannerMobile
                              : isTablet
                              ? facilities?.pageDetails.pageBannerTablet
                              : facilities?.pageDetails.pageBannerPC
                          }
                          alt={facilities?.pageDetails.pageTitle}
                          className="img-ratio"
                        />
                      </div>
                    </div>

                    <div className="hero__content text-center">
                      <div>
                        <Fade direction="up" delay="100">
                          <h1 className="hero__title capitalize text-white">
                            {facilities?.pageDetails.pageTitle}
                          </h1>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "18px" }}
                    fontSize="inherit"
                  />
                  {language === "ru"
                    ? "На главную"
                    : language === "en"
                    ? "Home"
                    : "Startseite"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "aladdin-beach-resort"
                    ? "Aladdin Beach Resort "
                    : hotelUrl === "ali-baba-palace-hotel" ||
                      hotelUrl === "ali-baba-palace"
                    ? "Ali Baba Palace"
                    : "Jasmine Village"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {facilities?.pageDetails.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-xl-6 col-lg-8 col-md-10">
                  <h2 className="text-64 md:text-40 lh-11">
                    {" "}
                    {facilities?.pageDetails.pageTitle}
                  </h2>
                  <p className="mt-40">{facilities?.pageDetails.pageText}</p>
                </div>
              </div>
            </div>
            <div className="row pt-50 px-60 md:px-15 facilities-list">
              {facilities.facilities?.map((facility) => (
                <div
                  className="col-12 col-md-6 col-lg-4 mb-30  baseCard -type-1 -padding-lg"
                  key={facility.facilityUrl}
                >
                  <div
                    className={`baseCard__image ratio ratio-45:54 rounded-16`}
                  >
                    <Slide className="img-ratio rounded-16" triggerOnce="true">
                      <img
                        src={facility.facilityPhotoHome}
                        alt={facility.facilityName}
                        className="img-ratio rounded-16"
                      />
                    </Slide>
                  </div>
                  <Link
                    to={`${
                      facility.isMoreDetails
                        ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                        : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                    }`}
                    className="baseCard__content d-flex flex-column justify-end text-center"
                  >
                    {/* <div
                      className={`${facility.facilityClassIcon} text-40 text-white mb-20`}
                  ></div> */}
                    <h4 className="text-30 md:text-25 text-white">
                      {facility.facilityName}
                    </h4>
                    <p className="text-10 md:text-10 text-white mt-20">
                      {facility.facilitySummery}
                    </p>
                  </Link>
                </div>
              ))}
              {/*{facilities.facilities?.map((facility, index) =>
                index % 2 === 0 ? (
                  <div
                    className="roomCard -type-2 "
                    key={facility.facilityUrl}
                    id={facility.facilityUrl}
                  >
                    <div className="row flex-column-reverse flex-lg-row x-gap-30 y-gap-40 items-center pt-100 sm:pt-50">
                      <div className="  col-lg-5 offset-lg-1 col-md-6">
                        <div className="roomCard__content">
                          <div className="d-flex justify-between items-end">
                            <h3 className="roomCard__title lh-065 text-40 md:text-40">
                              {facility.facilityName}
                            </h3>
                          </div>

                          <p className="mt-40 md:mt-30">
                            {facility.facilitySummery}{" "}
                          </p>
                          {facility.isMoreDetails && (
                            <button
                              className="button -md -type-2 -outline-accent-1 mt-40 md:mt-30"
                              onClick={() =>
                                navigate(
                                  `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                )
                              }
                            >
                              More DETAIL{" "}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div>
                          <Link
                            to={`${
                              facility.isMoreDetails
                                ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                            }`}
                            className="roomCard__image -no-rounded ratio ratio-68:50"
                          >
                            <Slide className="img-ratio">
                              <img
                                src={facility.facilityPhotoHome}
                                alt={facility.facilityName}
                                className="img-ratio"
                              />
                            </Slide>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="roomCard -type-2"
                    key={facility.facilityUrl}
                    id={facility.facilityUrl}
                  >
                    <div className="row x-gap-30 y-gap-40 items-center pt-100 sm:pt-50">
                      <div className="col-lg-6 col-md-6">
                        <div>
                          <Link
                            to={`${
                              facility.isMoreDetails
                                ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                            }`}
                            className="roomCard__image -no-rounded ratio ratio-68:50"
                          >
                            <Slide className="img-ratio">
                              <img
                                src={facility.facilityPhotoHome}
                                alt={facility.facilityName}
                                className="img-ratio"
                              />
                            </Slide>
                          </Link>
                        </div>
                      </div>
                      <div className="  col-lg-5 offset-lg-1 col-md-6">
                        <div className="roomCard__content">
                          <div className="d-flex justify-between items-end">
                            <h3 className="roomCard__title lh-065 text-40 md:text-40">
                              {facility.facilityName}
                            </h3>
                          </div>

                          <p className="mt-40 md:mt-30">
                            {facility.facilitySummery}
                          </p>
                          {facility.isMoreDetails && (
                            <button
                              className="button -md -type-2 -outline-accent-1 mt-40 md:mt-30"
                              onClick={() =>
                                navigate(
                                  `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                )
                              }
                            >
                              More DETAIL{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}*/}
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Facilities;
